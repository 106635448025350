import React from 'react';
import styled from 'styled-components';
import IconError from 'assets/ErrorIcon';
import { CustomTypography } from 'components/atoms/Label/Label';
import { Button, useMediaQuery } from '@mui/material';

const LayOutContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-top: 40px;
    padding-bottom: 85px;
    width: 100%;
    max-width: 558px;
    margin: auto;
  `;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-left: 24px;
  padding-right: 24px;
  gap: 24px;
  align-items: center;
  max-width: 558px;
  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const ErrorTemplate = () => {
    const matches = useMediaQuery('(min-width:600px)');
    React.useEffect(() => {
        window.dataLayer.push({ 
            event: 'Rappi_pagina_error_v',
            tipo_seguro: undefined,
            correo: undefined,
            telefono: undefined,
            page_location: window.location.href
         });
    }, [])

    return(
        
        <LayOutContainer>
            <Wrapper>
                <CustomTypography
                    style={{
                    fontSize: matches ? 28 : 24,
                    fontWeight: 700,
                    letterSpacing: "0.5px",
                    textAlign: "center",
                    Padding: "0 1rem",
                    textWrap: "balance",
                    }}
                >
                    ¡Oops! Estamos presentando unos problemas con nuestra plataforma
                </CustomTypography>
                <br />
                <div className='imageBanner'>
                    <div>
                    <IconError />
                    </div>
                </div>
                <CustomTypography
                    style={{
                    fontSize: matches ? 24 : 20,
                    fontWeight: 400,
                    letterSpacing: "0.5px",
                    textAlign: "center",
                    Padding: "0 1rem",
                    textWrap: "balance",
                    borderRadius: 4,
                    padding: '8px 16px'
                    }}
                >
                    No te preocupes ya estamos trabajando para resolverlo. Regresa más tarde.
                </CustomTypography> <br/>
                <Button
                        type="submit"
                        size={"medium"}
                        variant={"contained"}
                        onClick={() => {
                            window.dataLayer.push({
                                event: 'Rappi_pagina_error',
                                boton_contacto: 'Entendido',
                                page_location: window.location.href
                           });
                        }
                        }
                        style={
                        {
                            borderRadius: 20,
                            width: "100%",
                            padding: "14px 16px",
                            background: "var(--Rappi-neon, #FE3F23)",
                            color: "#FFF",
                            opacity: '0.8',
                            fontSize: 16,
                            fontWeight: 600,
                            textTransform: 'uppercase',
                            lineHeight: 'normal'
                        }
                        }
                    >
                        Listo
                    </Button>
            </Wrapper>
        </LayOutContainer>
    )
}

export default ErrorTemplate;
