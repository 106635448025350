import React from "react";
import { useSelector } from 'react-redux';
import { Formik, Form } from "formik";
import { Button } from "@mui/material";
import { styled } from "styled-components";
import FormSteppers from "../FormSteppers";
import FormObserver from "../FormikContexCustom";
// import useLocalStorage from "../../../../hooks/useLocalStorage";
import "yup-phone-lite";
// import LoadingButton from '@mui/lab/LoadingButton';
import { CustomTypography } from "../../../atoms/Label/Label";
// import Yup from 'yup'
import { IStorageMembershipData } from "../../../../utils/localStorageKeys";
const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
`;

type FormContainerProps = {
  renderStepContent: any;
  steps: string[];
  initialValues: any;
  activeStep: number;
  setActiveStep: any;
  title: string[];
  subTitle?: string[];
  _handleSubmit: any;
  validatorSchemaStepper: any;
  localStorageOmit?: Array<string>;
  formObserverKey?: string;
  initialValueImportant?: boolean;
  buttonNext?: boolean;
  formObserverSlice?: any;
  localData?: any;
  buttonText?: string | React.ReactNode;
  formikRef?: any;
  loading?: boolean;
};

const FormContainer: React.FC<FormContainerProps> = ({
  renderStepContent,
  steps,
  initialValues,
  activeStep,
  title,
  subTitle,
  _handleSubmit,
  validatorSchemaStepper,
  localStorageOmit,
  formObserverKey,
  initialValueImportant,
  buttonNext = true,
  formObserverSlice,
  localData,
  buttonText = "Continuar",
  formikRef,
  loading = false
}) => {

  const validationFields = validatorSchemaStepper().isValidSync(localData);
  let storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.currentValue.response),
  };
  const userRappi = useSelector((state: any) => state.userRappi.response) as
  | any
  | null;

  React.useEffect(() => {
    if (activeStep == 0 && window.location.pathname == "/registrate/1") {
      window.dataLayer.push({
        event: 'Rappi_validar_correo_v',
        tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
        correo: undefined,
        telefono: undefined,
        page_location: window.location.href,
      });
    } else if (activeStep == 1 && window.location.pathname == "/registrate/2") {
      window.dataLayer.push({
        event: 'Rappi_validar_codigo_v',
        tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
        correo: localData?.email,
        telefono: undefined,
        page_location: window.location.href,
      });
    } else if (activeStep == 2 && window.location.pathname == "/registrate/3") {
      window.dataLayer.push({
        event: 'Rappi_validar_informacion_v',
        tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
        correo: localData?.email, 
        telefono: userRappi?.phone,
        page_location: window.location.href,
     });
    } else if (activeStep == 3 && window.location.pathname == "/registrate/4") {
      window.dataLayer.push({
        event: 'Rappi_pagar_v',
        tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
        correo: localData?.email, 
        telefono: userRappi?.phone,
        page_location: window.location.href,
      });
    } else if (activeStep == 0 && window.location.pathname == "/actualizar"){
      window.dataLayer.push({
        event: 'Rappi_conversion_validar_v',
        tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
        correo: localData?.email, 
        telefono: userRappi?.phone,
        page_location: window.location.href,
      });
    }
  }, [activeStep, window.location.pathname])


  const sendGtmStep0 = () => {
    if (activeStep == 0 && window.location.pathname == "/registrate/1"){
      window.dataLayer.push({
        event: 'Rappi_validar_correo',
        correo: localData?.email,
        boton_contacto: 'Continuar',
        tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
      });
    } else if (activeStep == 0 && window.location.pathname == "/actualizar"){
      window.dataLayer.push({
        event: 'Rappi_conversion_validar',
        telefono: userRappi?.phone,
        boton_contacto: 'Continuar',
        correo: localData?.correo,
        tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
   });

    }
  }
  const sendGtmStep1 = () => {
    if (activeStep == 1 && window.location.pathname == "/registrate/2") {
      window.dataLayer.push({
        event: 'Rappi_validar_codigo',
        correo: localData?.email,
        boton_contacto: 'Continuar',
        tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
      });
    }
  }
  const sendGtmStep2 = () => {
    window.dataLayer.push({
      event: 'Rappi_validar_informacion',
      telefono: userRappi?.phone,
      boton_contacto: 'Confirmar',
      correo:localData?.email,
      tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
    })
  }
  const sendGtmStep3 = () => {
    // window.dataLayer.push({
    //   event: 'TRUS_registro_validacion',
    //   boton_contacto: 'Continuar',
    //   correo: userData?.correo,
    //   telefono: userData?.telefono,
    //   tipo_seguro: storage?.type == "free" ? "De regalo" : "Premium"
    // })
  }
  const sendGtmStep4 = () => {
    // if(storage?.type == "free"){
    //   // window.dataLayer.push({
    //   //   event: 'TRUS_miseguro_freemium',
    //   //   seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
    //   //   tipo_seguro: "De regalo",
    //   //   telefono: userData?.telefono,
    //   //   correo: userData?.correo,
    //   //   boton_contacto: 'Continuar',
    //   // })
    // } else if (storage.type == "premium"){
    // //   window.dataLayer.push({
    // //     event: 'TRUS_miseguro_premium',
    // //     seguro: storage.category == "celular" ? "Celular Protect" : "Salud Protect",
    // //     tipo_seguro: "Premium",
    // //     telefono: userData?.telefono,
    // //     correo: userData?.correo,
    // //     boton_contacto: "Continuar",
    // // })
    // }
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={
          initialValueImportant ? initialValues : localData || initialValues
        }
        validationSchema={() => validatorSchemaStepper()}
        onSubmit={_handleSubmit}
      >
        {({ handleChange, handleBlur, isSubmitting, errors }) => (
          <Form
            noValidate
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              gap: 40,
            }}
          >
            <FormObserver
              getFormikReference={formikRef}
              localStorageOmit={localStorageOmit}
              formObserverSlice={formObserverSlice}
            />
            <FormSteppers subTitle={subTitle} title={title} activeStep={activeStep} steps={steps} />
            <LayOutContainer>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 40,
                  alignItems: "center",
                }}
              >
                {renderStepContent(
                  activeStep,
                  errors,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  validationFields
                )}
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: '100%'
              }}>

                {buttonNext && (
                    <Button
                      type="submit"
                      size={"medium"}
                      variant={"contained"}
                      disabled={isSubmitting || !validationFields}
                      onClick={() => {
                        if (activeStep == 0){
                          sendGtmStep0();
                        } else if (activeStep == 1){
                          sendGtmStep1();
                        } else if (activeStep == 2) {
                          sendGtmStep2();
                        } else if (activeStep == 3){
                          sendGtmStep3();
                        } else if (activeStep == 4){
                          sendGtmStep4();
                        }
                      }
                      }
                      style={
                        {
                          borderRadius: 20,
                          width: "100%",
                          padding: "14px 16px",
                          background: !validationFields ? "#cfcfcf" : "var(--Rappi-neon, #FE3F23)",
                          color: !validationFields ? "gray" : "#FFF",
                          opacity: '0.8',
                          fontSize: 16,
                          fontWeight: 600,
                          textTransform: 'uppercase',
                          lineHeight: 'normal'
                        }
                      }
                    >
                      {buttonText}
                    </Button>
                  )
                }
                {buttonNext && (<CustomTypography
                  style={{
                    width: '100%',
                    fontWeight: 400,
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontHeight: 'normal'
                  }}
                >*Campos obligatorios</CustomTypography>)}
              </div>
            </LayOutContainer>
          </Form>
        )}
      </Formik>
    </React.Fragment >
  );
};

export default FormContainer;
