import React, { useEffect } from 'react';
import styled from 'styled-components';
import IconSuccess from 'assets/SuccessSignUp';
import { CustomTypography } from 'components/atoms/Label/Label';
import { Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import { restartValue } from 'api/formRegisterSlice';
import { restartValue as resetTrackingId } from 'api/trackingIdSlice';

import { useDispatch, useSelector } from 'react-redux';
import useFetch from 'hooks/useFetch';
import { apiSuccess, apiLoading, apiError } from 'api/signUpSlice';
import { IStorageMembershipData } from 'utils/localStorageKeys';

const LayOutContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-top: 40px;
    padding-bottom: 85px;
    width: 100%;
    max-width: 558px;
    margin: auto;
  `;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-left: 24px;
  padding-right: 24px;
  gap: 40px;
  align-items: center;
  max-width: 558px;
  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const SuccessSignUp = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {fetchData} = useFetch();
    const currentValue = useSelector((state: any) => state.currentValue.response) as any | null;
    const formRegister = useSelector((state: any) => state.formRegister.response) as any | null;
    const leads = useSelector((state: any) => state.leads.response) as any | null;
    const tracking = useSelector((state: any) => state.tracking.response) as
    | any
    | null;
    const signUpError = useSelector((state: any) => state.signUp.error) as
    | any
    | null;
    const signUpResponse = useSelector((state: any) => state.signUp.response) as
    | any
    | null;

    const userUpdate = useSelector((state: any) => state.trackingId.response) as
        | any
        | null;

    useEffect(() => {

        if (userUpdate){

        }else {
            fetchData("users/auth", "POST", {
                event: "register-rappi",
                content: {
                    "name":leads.data.name,
                    "phone":leads.data.phone,
                    "email":leads.data.email,
                    "tracking_id":tracking.data.tracking_id,
                    "rappi_id":leads.data.rappi_id,
                    "level":leads.data.level,
                    "status":true,
                    "rappi_status":leads.data.rappi_status,
                    "business":currentValue.type,
                    "plan_id":currentValue.memberShipProduct.plan_id,
                    "benefit_id":currentValue.memberShipProduct.benefits[0].benefit_id,
                    "tier":currentValue.subtype.toUpperCase()
                }
            }, {}, apiLoading, apiSuccess, apiError);
        }
    }, []);

    useEffect(()=> {
        if(signUpError && !userUpdate){
            navigate('/error')
        }
        if(!signUpError && userUpdate){
            //navigate('/error')
            dispatch(resetTrackingId())
            //alert(JSON.stringify(signUpResponse))

        }
    }, [signUpError,userUpdate]);

    const userResponse = useSelector((state: any) => state.formRegister.response);
    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.currentValue.response),
      };

    React.useEffect(() => {
        window.dataLayer.push({ 
            event: 'Rappi_seguro_listo_v',
            tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
            correo: userResponse?.email,
            telefono: userResponse?.phone,
            page_location: window.location.href,
        });        
    }, [])


    return(

        signUpResponse && !userUpdate ? <LayOutContainer>
            <Wrapper>
                <CustomTypography
                    style={{
                    fontSize: matches ? 24 : 18,
                    fontWeight: 700,
                    letterSpacing: "0.5px",
                    textAlign: "center",
                    Padding: "0 1rem",
                    textWrap: "balance",
                    }}
                >
                    ¡Listo! ya tienes Seguro de protección de ingreso!
                </CustomTypography>
                <CustomTypography
                    style={{
                    fontSize: matches ? 16 : 12,
                    fontWeight: 400,
                    letterSpacing: "0.5px",
                    textAlign: "center",
                    Padding: "0 1rem",
                    textWrap: "balance",
                    borderRadius: 4,
                    padding: '8px 16px',
                    backgroundColor: 'var(--black-5, #F4F4F4)'
                    }}
                >
                    Te enviaremos tu certificado y la guía de uso a tu email en X hrs
                </CustomTypography>
                <div className='imageBanner'>
                    <div>
                    <IconSuccess />
                    </div>
                </div>
                <Button
                        type="button"
                        size={"medium"}
                        variant={"contained"}
                        onClick={() => {
                            window.dataLayer.push({ 
                                event: 'Rappi_seguro_listo',
                                tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
                                correo: userResponse?.email, 
                                telefono: userResponse?.phone,
                                boton_contacto: 'Listo'
                            });
                            dispatch(restartValue());
                            navigate('/', { replace: true });
                        }
                        }
                        style={
                        {
                            borderRadius: 20,
                            width: "100%",
                            padding: "14px 16px",
                            background: "var(--Rappi-neon, #FE3F23)",
                            color: "#FFF",
                            opacity: '0.8',
                            fontSize: 16,
                            fontWeight: 600,
                            textTransform: 'uppercase',
                            lineHeight: 'normal'
                        }
                        }
                    >
                        Listo
                    </Button>
            </Wrapper>
        </LayOutContainer>
            :
            !signUpResponse && userUpdate ? <LayOutContainer>
                    <Wrapper>
                        <CustomTypography
                            style={{
                                fontSize: matches ? 24 : 18,
                                fontWeight: 700,
                                letterSpacing: "0.5px",
                                textAlign: "center",
                                Padding: "0 1rem",
                                textWrap: "balance",
                            }}
                        >
                            ¡Listo! ya tienes Seguro de protección de ingreso!
                        </CustomTypography>
                        <CustomTypography
                            style={{
                                fontSize: matches ? 16 : 12,
                                fontWeight: 400,
                                letterSpacing: "0.5px",
                                textAlign: "center",
                                Padding: "0 1rem",
                                textWrap: "balance",
                                borderRadius: 4,
                                padding: '8px 16px',
                                backgroundColor: 'var(--black-5, #F4F4F4)'
                            }}
                        >
                            Te enviaremos tu certificado y la guía de uso a tu email en X hrs
                        </CustomTypography>
                        <div className='imageBanner'>
                            <div>
                                <IconSuccess />
                            </div>
                        </div>
                        <Button
                            type="button"
                            size={"medium"}
                            variant={"contained"}
                            onClick={() => {
                                if (userUpdate) {
                                    dispatch(restartValue());
                                }else {
                                    dispatch(restartValue());
                                }
                                navigate('/', { replace: true });
                            }
                            }
                            style={
                                {
                                    borderRadius: 20,
                                    width: "100%",
                                    padding: "14px 16px",
                                    background: "var(--Rappi-neon, #FE3F23)",
                                    color: "#FFF",
                                    opacity: '0.8',
                                    fontSize: 16,
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    lineHeight: 'normal'
                                }
                            }
                        >
                            Listo
                        </Button>
                    </Wrapper>
                </LayOutContainer>


            : null
    )
}

export default SuccessSignUp;
