import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { styled } from "styled-components";

import FormContainer from "../../organisms/Form/FormContainer";
import { CustomButton } from "../../atoms/Buttons/Buttons";

import { apiSuccess, restartValue } from "../../../api/formRegisterSlice";
import { apiSuccess as apiSuccessCurrentValue } from "../../../api/currentValueSlice";

import { useDispatch, useSelector } from "react-redux";

import BasicModal from "../../molecules/BasicModal/BasicModal";
import CardAction from "../../organisms/Card";
import ManiPosesBody from "../../../assets/ManiErrorHandler/ManiPosesErrorHandler.jpg";

import ConfirmInfo from "components/organisms/Form/FormContent/ConfirmInfo";
import MakePaymentTemplate from "components/organisms/Form/FormContent/MakePayment";
import {isObject} from "formik";
import {MembershipPlansInfo} from "utils/Constants";


const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 85px;
  padding-left: 24px;
  padding-right: 24px;
  gap: 24px;
  align-items: flex-start;
  max-width: 558px;
  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

enum TypeCurrentValue {
  rappi = 'rappi'
}

enum SubtypeCurrentValue {
  premium = 'premium'
}

const UpgradeMembershipTemplate: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idStepper,trackingId } = useParams();
  const [buttonNextHidded, setButtonNextHidded] = useState<boolean>(true);
  // const webAuth: any = useContext(ContextAuth0);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  let [MemberShip, setMemberShip] = useState<MembershipPlansInfo[]>([]);


  const response = useSelector((state: any) => state.formRegister.response) as
    | any
    | null;
  const currentValue = useSelector((state: any) => state.currentValue.response) as
    | any
    | null;

  const responseMembershipProducts = useSelector((state: any) => state.membershipProducts.response) as  | any  | null;


  const [open, setOpenModal] = useState<boolean>(false);
  // const { fetchData } = useFetch();
  const actionsRef: any = useRef();
  const valuesRef: any = useRef();
  const formikRef: any = useRef();

  const fieldIsRequired: string = "Campo obligatorio";
  const membershipType: TypeCurrentValue = currentValue?.type || "rappi";
  const membershipSubtype: SubtypeCurrentValue = currentValue?.subtype || "premium";


  const generateArray =(objects: any )=>{
    let new_array: Array<MembershipPlansInfo> = []
    Object.keys(objects).forEach(function(key, index) {
      if (isObject(objects[key])){
        new_array.push(objects[key]);
      }
    });
    return new_array;
  }

  const selectedPlan =()=>{
    setMemberShip(generateArray(responseMembershipProducts.data))
  }

  useEffect(() => {
    selectedPlan();
  }, []);


  useEffect(() => {
    if (MemberShip){
      const premiumMemberShips = MemberShip.filter(membership => membership.type === 'premium' && membership.category === "ingresos");
      const dataToPremium = {
        type: "rappi",
        subtype: "premium",
        memberShipProduct:premiumMemberShips[0]

      }
      dispatch(apiSuccessCurrentValue(dataToPremium))
    }

  }, [MemberShip]);





  const membershipTypeModule = {
    rappi: {
      premium: {
        formSteps: 1, //starting from 0
        steps: [], // steps title
        titles: [
          "Ingresa tu correo electrónico para validar tu información",
          "Ingresa el código que recibiste de 6 dígitos"
        ], // content title
        subtitles:[],
        initialValues: {
          email: "",
          otp: "",
          fullName: "",
          phone: "",
          acceptTerms: false,
          mexicanciticen: true
        },
      }
    },
  };



  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderStepContent = (
    step: number,
    errors: any,
    handleChange: any,
    handleBlur: any
  ) => {
    switch (step) {
      case 0:
         setButtonNextHidded(true);
        return (
          <ConfirmInfo handleChange={handleChange}
                       handleBlur={handleBlur}
                       errors={errors} />
        );
      case 1:
         setButtonNextHidded(false);
        return (
          <MakePaymentTemplate handleChange={handleChange} onlyCardPresentation setActiveStep={setActiveStep} />
        );
      default:
        return <></>;
    }
  };

  const _submitForm: any = async (values: any, actions: any) => {
    navigate('/exito-registro', { replace: true })

  };
  const formSteps = membershipTypeModule[membershipType]?.[membershipSubtype]?.formSteps;

  const _handleSubmit = async (values: any, actions: any) => {
    const steppers = [
      ["email"],
      [
        "email",
        "fullName",
        "phone",
        "acceptTerms"
      ],
      [
        "email",
        "fullName",
        "phone",
        "acceptTerms"
      ],
      [
        "email",
        "fullName",
        "phone",
        "acceptTerms"
      ],
      [
        "email",
        "fullName",
        "phone",
        "acceptTerms"
      ],
      [
        "email",
        "fullName",
        "phone",
        "acceptTerms"
      ],
    ];

    let content: any = {};
    if(steppers[activeStep].length > 0){
    }

    content['step'] = activeStep;
    if (activeStep === formSteps) {
      _submitForm(valuesRef.current, actionsRef.current);
    } else {
      setActiveStep(activeStep + 1);
      actionsRef.current.setTouched({});
      actionsRef.current.setSubmitting(false);
    }

  }



  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]{2,}(\.[a-zA-Z]{2,})+$/,
        "Este correo no es valido"
      ).test('toLoweCase', '', (value: any) => {
        return value?.toLowerCase()
      })
      .required(fieldIsRequired)
  });

  const validationSchemaStep2 = Yup.object().shape({
    otp: Yup.string().min(6, "Minimo 6 caracteres").required(fieldIsRequired),
  });

  const validationSchemaStep3 = Yup.object().shape({
    fullName: Yup.string()
      .required(fieldIsRequired),
    phone: Yup.string()
      .phone("MX", "Por favor, ingresa un número de teléfono válido en México")
      .max(10, "maximo 10 caracteres")
      .required(fieldIsRequired),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      "Debes aceptar los términos y condiciones"
    )
  });

  const validationSchemaStepEmpty = Yup.object().shape({});

  const validatorSchemaStepper = () => {
    switch (activeStep) {
      case 0:
        return validationSchemaStep3;
      case 1:
        return validationSchemaStepEmpty;
      default:
        throw Error("Verificar que el schema este asignado");
    }
  };



  const clearFields = (dataToClear: Array<string>) => {
    dataToClear.length > 0 && dataToClear.filter((value) => {
      formikRef.current.setFieldValue(value, "");
    })
  }

  const getFormikReference = (formik: any) => {
    formikRef.current = formik;
  };

  useEffect(() => {
    setIsLogged(sessionStorage?.access_token ? true : false);
    /** eslint-disable-next-line react-hooks/exhaustive-deps**/
  }, [sessionStorage?.access_token])

  useEffect(() => {
    const stepper = Number(idStepper) || ''
    if(stepper && !isNaN(stepper) && stepper <= formSteps){
      setActiveStep(Number(idStepper) - 1);
    }
  }, []);

  return membershipTypeModule[membershipType]?.[membershipSubtype] ? (
    <FormWrapper>
      <BasicModal
        styleRoot={{
          maxWidth: 728,
          boxShadow: "none",
          gap: "2px",
          marginTop: "62px",
        }}
        styleContent={{
          margin: 0,
          padding: 0,
        }}
        disableBackdropClick={true}
        closeButton
        open={open}
        setOpenModal={setOpenModal}
        onClick={() => {
          //console.log('close modal')
        }}
        bodyDialog={
          <CardAction
            onClick={() => {
              //console.log('close modal')
            }}
            onClickSecondary={() => {
              //console.log('click secundario')
            }}
            buttonText={isLogged ? "Finalizar sesion" : "Continuar con el registro"}
            buttonTextSecondary="Nuevo registro"
            cardContent={
              isLogged ?
                "Para continuar con un nuevo registro debes de finalizar tu sesion"
                :
                "Contamos con datos ingresados previamente ¿Desea continuar o crear un nuevo registro? Al crear un nuevo registros los datos se perderan"
            }
            imgSrc={ManiPosesBody}
            secundaryButton={!isLogged}
            style={{
              backgroundColor: "#039ECC",
              border: "1px solid #039ECC",
            }}
            styleTextContent={{
              fontSize: "17px",
            }}
            styleImg={{
              backgroundColor: "#C0E6F2",
              padding: 0,
              display: "flex",
              flexWrap: "wrap",
              alignContent: "flex-end",
            }}
            styleImgCard={{
              padding: 0,
            }}
            styleButton={{
              color: "#039ECC",
            }}
            styleButtonSecondary={{
              color: "#039ECC",
            }}
          />
        }
      />
        <CustomButton
          text={"Regresar"}
          onClick={() => {
            if (activeStep === 0) {
            }
            activeStep === 0 ? navigate("/") : handleBack()
          }
          }
          size={"medium"}
          startIcon={<ArrowBack />}
          variant={"contained"}
          style={{
            color: "var(--Rappi-neon, #FE3F23)",
            backgroundColor: "transparent",
            fontSize: "1rem",
            textTransform: "none",
            fontWeight: "600",
            fontStyle: "normal",
            padding: "8px 10px",
            minWidth: "64px",
            border: "none",
            justifyContent: "flex-start",
            width: "fit-content",
            lineHeight: 'normal'
          }}
        />
      <FormContainer
        buttonNext={buttonNextHidded}
        steps={membershipTypeModule[membershipType]?.[membershipSubtype]?.steps}
        initialValues={membershipTypeModule[membershipType]?.[membershipSubtype]?.initialValues}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        title={membershipTypeModule[membershipType]?.[membershipSubtype]?.titles}
        subTitle={membershipTypeModule[membershipType]?.[membershipSubtype]?.subtitles}
        renderStepContent={membershipTypeModule[membershipType]?.[membershipSubtype] && renderStepContent}
        _handleSubmit={_handleSubmit}
        validatorSchemaStepper={validatorSchemaStepper}
        formObserverKey="FormObserver"
        formObserverSlice={apiSuccess}
        localData={response}
        formikRef={getFormikReference}
      />
    </FormWrapper>
  ) : null;
};

export default UpgradeMembershipTemplate;
