import React from "react";
import UpgradeMembershipTemplate from "components/templates/UpgradeMembershipTemplate"

const UpgradeMembership:React.FC = () => {

    return (
        <UpgradeMembershipTemplate />
    )
}

export default UpgradeMembership;
