import React from 'react';
import BasicModal from 'components/molecules/BasicModal/BasicModal';
import errorIcon from '../../../../../assets/HiringPaymentMembershipAssets/errorModalPayment.svg'
import successIcon from '../../../../../assets/HiringPaymentMembershipAssets/successModalPayment.svg';
import {Box, CardMedia} from "@mui/material";
import {CustomTypography} from "components/atoms/Label/Label";
import {CustomButton} from "components/atoms/Buttons/Buttons";

type ModalPaymentmethodProps = {
    open: boolean;
    setOpenModal: any;
    onClick: any;
    success: boolean;
    errorMessage? :string
}
const ModalAlertPayment: React.FC<ModalPaymentmethodProps> = ({open = false, setOpenModal, onClick, success,errorMessage}) => {
    return (
        <BasicModal
            styleRoot={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 728,
                boxShadow: "none",
                marginTop: "62px",
            }}
            styleContent={{
                display: "flex",
                width: "327px",
                padding: success ? "60px 0px" : "16px 0px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
                borderRadius: "8px",
                background: "var(--White, #FFF)",
                boxShadow: "4px 6px 16px 0px rgba(33, 33, 33, 0.05)",

            }}
            disableBackdropClick={true}
            closeButton={false}
            open={open}
            setOpenModal={setOpenModal}
            onClick={onClick}
            bodyDialog={
                <Box sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: 'column',
                }}>
                    <CardMedia
                        component="img"
                        image={success ? successIcon : errorIcon}
                        alt="mani poses"
                        sx={{
                            width: '80px',
                            objectFit: "contain",
                        }}
                    />


                    {
                        !success &&
                      <Box sx={{padding: '16px 16px  16px 16px'}}>
                        <CustomTypography
                          style={{
                              alignSelf: "stretch",
                              color: "var(--Black, #212121)",
                              textAlign: "center",
                              fontFamily: "Montserrat",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "normal",
                          }}
                        >
                          ¡Oops!
                          Tuvimos un problema al procesar tu pago
                        </CustomTypography>
                      </Box>
                    }
                    <Box sx={{padding: success ? '16px 16px  0 16px' : '0 16px  0 16px'}}>
                        <CustomTypography
                            style={{
                                alignSelf: "stretch",
                                color: "var(--Black, #212121)",
                                textAlign: "center",
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                            }}
                        >
                            {success ? "¡Tu pago se ha realizado con éxito!" :  errorMessage !=="" ? errorMessage :"Inténtalo nuevamente más tarde"}
                        </CustomTypography>
                    </Box>


                    {
                        !success &&
                      <Box sx={{
                          width: "100%",
                          display: "flex"
                      }}>
                        <Box sx={{
                            width: "100%",
                            display: "flex", padding: '16px 16px  0px 16px'
                        }}>
                          <CustomButton
                            onClick={() => {
                                if (onClick) {
                                    onClick();
                                }
                            }}
                            text={"Aceptar"}
                            style={{
                                color: "#FFF",
                                textAlign: "center",
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                textTransform: "uppercase",
                                width: "100%",
                                display: "flex",
                                padding: "14px 16px",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                                alignSelf: "stretch",
                                borderRadius: "20px",
                                background: "var(--Rappi-neon, #FE3F23)",
                            }}
                          />
                        </Box>
                      </Box>
                    }

                </Box>
            }
        />
    );
}

export default ModalAlertPayment;
