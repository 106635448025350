import React from 'react';
import {Box, Container, Divider, Grid, Link, Stack, Typography} from '@mui/material';
import LogoLight from './assets/logo-light.png';
import RappiBlanco from '../../../assets/Logo/RappiBlanco.svg';

interface WelcomeProps {
    // Define las propiedades del componente aquí
}

const FooterInter: React.FC<WelcomeProps> = () => {
    // Implementa el componente aquí
    return (
        <Box
            component={'footer'}
            sx={{bgcolor: '#282828'}}
            bottom="0px"
        >
            <Container
                maxWidth={'xl'}
                style={{
                    width: '100%',
                    marginTop: '20px'
                }}>
                <Grid container spacing={5} marginTop="0" marginLeft="0px" style={{width: '100%'}}>
                    <Grid item xs={12} md={4}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                        >
                            <Box
                                component={'img'}
                                src={RappiBlanco}
                                sx={{
                                    width: {xs: 180, md: 160},
                                    maxWidth: {xs: 180, md: 180},
                                }}
                                alt={'RappiBlanco'}
                            />
                            <Box
                                component={'img'}
                                src={LogoLight}
                                sx={{
                                    width: {xs: 180, md: 160},
                                    maxWidth: {xs: 180, md: 180},
                                }}
                                alt={'Inter, seguro, seguro'}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                variant={'subtitle1'}
                                color={'white'}
                                fontWeight={'bold'}
                                style={{cursor: 'pointer'}}
                            >
                                {'Contáctanos'}
                            </Typography>
                            <Box marginBottom={"0px"}/>

                            <Typography
                                variant={'subtitle2'}

                                color={'white'}
                                title={'Tel'}
                                fontWeight={'400'}
                                style={{
                                    cursor: 'pointer',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                fontSize={"16px"}
                            >
                                {'55 8979 0980'}
                            </Typography>
                            <Typography
                                variant={'subtitle2'}

                                color={'white'}
                                title={'hola@inter.mx'}
                                fontWeight={'400'}
                                style={{
                                    cursor: 'pointer',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                                fontSize={"16px"}
                            >
                                {'hola@inter.mx'}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box marginBottom={"0px"}/>
                        <Typography variant={'subtitle1'} color={'white'} fontWeight={'bold'}>
                            {'Clientes'}
                        </Typography>
                        <Link href=" https://inter.mx/aviso-de-privacidad"
                              target={'_blank'}>
                            <Typography
                                variant={'subtitle2'}
                                color={'white'}
                                fontWeight={'400'}
                                style={{cursor: 'pointer'}}
                                fontSize={"16px"}
                            >
                                {'Aviso de Privacidad'}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    </Grid>
                </Grid>

                <Box paddingY={2}/>
                <Box marginBottom={4}/>
                <Box
                    paddingY={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Typography
                        textAlign={'center'}
                        variant={'caption'}
                        color={'white'}
                        style={{fontWeight: 300}}
                    >
                        {'Copyright 2023 ® Interprotección'}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default FooterInter;
