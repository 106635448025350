export enum MODES {
    develop = "develop",
    qa = "qa",
    production = "production"
}

export type ModesTypes = keyof typeof MODES;

export interface IConfig {
    base: string;
    backend: string;
    stripe: {
        publicKey: string;
    }
}

export type ConfigEnv = {
    [key in MODES]: IConfig
}