import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { styled } from "styled-components";
import FormContainer from "../../organisms/Form/FormContainer";
import { CustomButton } from "../../atoms/Buttons/Buttons";
import { apiSuccess } from "../../../api/formRegisterSlice";
import {
  restartValueError as restartLeadsError,
  restartValue as restartLeads
} from "../../../api/leadsSlice";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "hooks/useFetch";
import {
  apiLoading as apiLoadingLeads,
  apiSuccess as apiSuccessLeads,
  apiError as apiErrorLeads,
} from "../../../api/leadsSlice";
import {
  apiLoading as apiLoadingValidateUser,
  apiSuccess as apiSuccessValidateUser,
  apiError as apiErrorValidateUser,
  clearSuccess as clearSuccessValidateUser
} from "../../../api/validateUserSlice";
import {
  apiLoading as apiLoadingUserRappi,
  apiSuccess as apiSuccessUserRappi,
  apiError as apiErrorUserRappi,
} from "../../../api/userRappiSlice";
import BasicModal from "../../molecules/BasicModal/BasicModal";
import CardAction from "../../organisms/Card";
import ManiPosesBody from "../../../assets/ManiErrorHandler/ManiPosesErrorHandler.jpg";
import ValidateInfo from "components/organisms/Form/FormContent/ValidateInfo";
import ValidateCodeOTP from "components/organisms/Form/FormContent/ValidateCodeOTP";
import ConfirmInfo from "components/organisms/Form/FormContent/ConfirmInfo";
import MakePaymentTemplate from "components/organisms/Form/FormContent/MakePayment";
import { fetchTrackingStore } from "api/fetchingTrackingStore";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 85px;
  padding-left: 24px;
  padding-right: 24px;
  gap: 24px;
  align-items: flex-start;
  max-width: 558px;
  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

enum TypeCurrentValue {
  rappi = 'rappi'
}

enum SubtypeCurrentValue {
  freemium = 'freemium',
  premium = 'premium'
}

const SignUp: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idStepper } = useParams();
  const [buttonNextHidded, setButtonNextHidded] = useState<boolean>(true);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [onSubmitActivated, setOnSubmitActivated] = useState("inactive");
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const loadingLeads = useSelector((state: any) => state.leads.loading) as | any | null;
  const errorLeads = useSelector((state: any) => state.leads.error) as | any | null;
  const response = useSelector((state: any) => state.formRegister.response) as
    | any
    | null;
  const currentValue = useSelector((state: any) => state.currentValue.response) as
    | any
    | null;
  const validateUser = useSelector((state: any) => state.validateUser.response) as
  | any
  | null;
  const tracking = useSelector((state: any) => state.tracking.response) as
    | any
    | null;
  const leads = useSelector((state: any) => state.leads.response) as any | null;
  const [open, setOpenModal] = useState<boolean>(false);
  const { fetchData } = useFetch();
  const actionsRef: any = useRef();
  const valuesRef: any = useRef();
  const formikRef: any = useRef();

  const fieldIsRequired: string = "Campo obligatorio";
  const membershipType: TypeCurrentValue = currentValue?.type;
  const membershipSubtype: SubtypeCurrentValue = currentValue?.subtype;

  useEffect(() => {
    if(!currentValue?.type){
      navigate(-1);
    }
  }, []);

  const getValuesByPropertyValue = (object: any, propertyCopied: any) => {
    let newObject: { [key: string]: any } = {};

    for (const property of propertyCopied) {
      if (object.hasOwnProperty(property)) {
        newObject[property] = typeof object[property] === "string" ? object[property].trim() : object[property];
      }
    }

    return newObject;
  };

  const membershipTypeModule = {
    rappi: {
      freemium:{
        formSteps: 2, //starting from 0
        steps: ["Paso 1 de 3", "Paso 2 de 3 ", "Paso 3 de 3"], // steps title
        titles: [
          "Ingresa tu correo electrónico para validar tu información",
          "Ingresa el código que recibiste de 6 dígitos",
          "Para terminar, verifica tu información",
        ], // content title
        subtitles:["Te enviaremos un código via SMS y correo electrónico"],
        initialValues: {
          email: "",
          otp: "",
          fullName: "",
          phone: "",
          acceptTerms: false,
          mexicanciticen: true
        },
      },
      premium: {
        formSteps: 3, //starting from 0
        steps: ["Paso 1 de 4", "Paso 2 de 4", "Paso 3 de 4", "Paso 4 de 4"], // steps title
        titles: [
          "Ingresa tu correo electrónico para validar tu información",
          "Ingresa el código que recibiste de 6 dígitos",
          "Verifica tu información",
          "Datos de pago"
        ], // content title
        subtitles:["Te enviaremos un código via SMS y correo electrónico"],
        initialValues: {
          email: "",
          otp: "",
          fullName: "",
          phone: "",
          acceptTerms: false,
          mexicanciticen: true
        },
      }
    },
  };

  useEffect(() => {
    if(idStepper){
      navigate(`/registrate/${activeStep + 1}`)
    }
  }, [activeStep]);

  const handleBack = () => {
    window.dataLayer.push({
      event: 'Rappi_validar_regresar',
      boton_contacto: "Regresar",
      paso: `Paso ${activeStep + 1}`,
    })
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderStepContent = (
    step: number,
    errors: any,
    handleChange: any,
    handleBlur: any
  ) => {
    switch (step) {
      case 0:
         setButtonNextHidded(true);
        return (
          <ValidateInfo handleChange={handleChange} handleBlur={handleBlur} />
        );
      case 1:
         setButtonNextHidded(true);
        return (
          <ValidateCodeOTP handleChange={handleChange} />
        );
      case 2:
         setButtonNextHidded(true);
        return (
          <ConfirmInfo handleChange={handleChange}
                       handleBlur={handleBlur}
                       errors={errors} />
        );
      case 3:
         setButtonNextHidded(false);
        return (
          <MakePaymentTemplate handleChange={handleChange} onlyCardPresentation setActiveStep={setActiveStep} />
        );
      default:
        return <></>;
    }
  };

  const _submitForm: any = async (values: any, actions: any) => {
    navigate('/exito-registro', { replace: true })
  };
  const formSteps = membershipTypeModule[membershipType]?.[membershipSubtype]?.formSteps;

  const _handleSubmit = async (values: any, actions: any) => {
    actions.setTouched({});
    actions.setSubmitting(true);
    actionsRef.current = actions;
    valuesRef.current = values;

    // jane.smith@example.com
    fetchData(
      `users/auth/validate-user-rappi/${values?.email}`, "GET", {}, {},
      apiLoadingValidateUser,
      apiSuccessValidateUser,
      apiErrorValidateUser
    )

  }

  useEffect(() => {
    if(validateUser?.ok && validateUser?.data && valuesRef.current){
      dispatch(clearSuccessValidateUser());

      let content: any = {};

      const steppers = [
        ["email"],
        [
          "email",
          "otp"
        ],
        [
          "email",
          "otp",
          "fullName",
          "phone"
        ]
      ];

      if(steppers[activeStep].length > 0){
        content = getValuesByPropertyValue(valuesRef.current, steppers[activeStep]);
      }

      content = {
        ...content,
        "tracking_id": tracking?.data?.tracking_id,
        "email": valuesRef.current?.email,
        "business": currentValue?.type,
        tier: currentValue?.subtype.toUpperCase(),
        plan_id: currentValue?.memberShipProduct.plan_id,
        benefit_id: currentValue?.memberShipProduct?.benefits[0]?.benefit_id
      };

      fetchTrackingStore(
        "/users/leads",
        "save-lead",
        {
          content: {
            ...content,
          },
        },
        fetchData,
        apiLoadingLeads,
        apiSuccessLeads,
        apiErrorLeads
      );
    }
  }, [validateUser, valuesRef.current]);

  useEffect(() => {
    if (loadingLeads && onSubmitActivated === "inactive") {
      setOnSubmitActivated("pending");
    } else if (!loadingLeads && onSubmitActivated === "pending" && !errorLeads) {
      setOnSubmitActivated("active");
    } else if (errorLeads) {
      dispatch(restartLeadsError());
      setOnSubmitActivated("inactive");
      if(errorLeads.response.data.data.message !== "El OTP no es valido"){
        navigate('/lo-sentimos')
      }
      // actionsRef.current.setSubmitting(false);
    }
  }, [loadingLeads]);

  useEffect(() => {
    if(leads?.ok && actionsRef.current && valuesRef.current && onSubmitActivated === "active" && leads?.data?.rappi_id){
      dispatch(apiSuccessUserRappi(leads.data));
    }
    if (leads?.ok && actionsRef.current && valuesRef.current && onSubmitActivated === "active") {
      setOnSubmitActivated("inactive")
      if (activeStep === formSteps) {
        _submitForm(valuesRef.current, actionsRef.current);
      } else {
          setActiveStep(activeStep + 1);
          actionsRef.current.setTouched({});
          actionsRef.current.setSubmitting(false);
        }
    }
  }, [onSubmitActivated]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]{2,}(\.[a-zA-Z]{2,})+$/,
        "Este correo no es valido"
      ).test('toLoweCase', '', (value: any) => {
        return value?.toLowerCase()
      })
      .required(fieldIsRequired)
  });

  const validationSchemaStep2 = Yup.object().shape({
    otp: Yup.string().min(6, "Minimo 6 caracteres").required(fieldIsRequired),
  });

  const validationSchemaStep3 = Yup.object().shape({
    fullName: Yup.string()
      .required(fieldIsRequired),
    phone: Yup.string()
      .phone("MX", "Por favor, ingresa un número de teléfono válido en México")
      .max(10, "maximo 10 caracteres")
      .required(fieldIsRequired),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      "Debes aceptar los términos y condiciones"
    )
  });

  const validationSchemaStepEmpty = Yup.object().shape({});

  const validatorSchemaStepper = () => {
    switch (activeStep) {
      case 0:
        return validationSchema;
      case 1:
        return validationSchemaStep2;
      case 2:
        return validationSchemaStep3;
      case 3:
        return validationSchemaStepEmpty;
      case 4:
        return validationSchemaStepEmpty;
      default:
        throw Error("Verificar que el schema este asignado");
    }
  };

  const clearFields = (dataToClear: Array<string>) => {
    dataToClear.length > 0 && dataToClear.filter((value) => {
      formikRef.current.setFieldValue(value, "");
    })
  }

  // const restartRegister = () => {
  //   dispatch(restartValue());
  //   dispatch(restartBrand());
  //   dispatch(restartModels());
  //   dispatch(restartLeads());
  //   dispatch(restartDescription());
  //   dispatch(restartUser());
  //   dispatch(restartSignUp());
  //   clearFields([
  //     "name",
  //     "middle_name",
  //     "last_name",
  //     "birthdate",
  //     "mexicanciticen",
  //     "zipcode",
  //     "email",
  //     "phone",
  //     "acceptTerms",
  //     "marca",
  //     "modelo",
  //     "descripcion",
  //     "imei",
  //     "gender",
  //     "otp"
  //   ])

  //   fetchTrackingStore(
  //     "users/tracking",
  //     "create-tracking",
  //     {
  //       content: {
  //         data: {},
  //         ...getBrowserInfo(),
  //       },
  //     },
  //     fetchData,
  //     loadingTracking,
  //     successTracking,
  //     errorTracking
  //   );
  //   setOpenModal(false);
  // };

  // const onClickSecondary = () => {
  //   restartRegister();
  // };

  const getFormikReference = (formik: any) => {
    formikRef.current = formik;
  };

  useEffect(() => {
    setIsLogged(sessionStorage?.access_token ? true : false);
    /** eslint-disable-next-line react-hooks/exhaustive-deps**/
  }, [sessionStorage?.access_token])

  return membershipTypeModule[membershipType]?.[membershipSubtype] ? (
    <FormWrapper>
      <BasicModal
        styleRoot={{
          maxWidth: 728,
          boxShadow: "none",
          gap: "2px",
          marginTop: "62px",
        }}
        styleContent={{
          margin: 0,
          padding: 0,
        }}
        disableBackdropClick={true}
        closeButton
        open={open}
        setOpenModal={setOpenModal}
        onClick={() => {
          //console.log('close modal')
        }}
        bodyDialog={
          <CardAction
            onClick={() => {
              //console.log('close modal')
            }}
            onClickSecondary={() => {
              //console.log('click secundario')
            }}
            buttonText={isLogged ? "Finalizar sesion" : "Continuar con el registro"}
            buttonTextSecondary="Nuevo registro"
            cardContent={
              isLogged ?
                "Para continuar con un nuevo registro debes de finalizar tu sesion"
                :
                "Contamos con datos ingresados previamente ¿Desea continuar o crear un nuevo registro? Al crear un nuevo registros los datos se perderan"
            }
            imgSrc={ManiPosesBody}
            secundaryButton={!isLogged}
            style={{
              backgroundColor: "#039ECC",
              border: "1px solid #039ECC",
            }}
            styleTextContent={{
              fontSize: "17px",
            }}
            styleImg={{
              backgroundColor: "#C0E6F2",
              padding: 0,
              display: "flex",
              flexWrap: "wrap",
              alignContent: "flex-end",
            }}
            styleImgCard={{
              padding: 0,
            }}
            styleButton={{
              color: "#039ECC",
            }}
            styleButtonSecondary={{
              color: "#039ECC",
            }}
          />
        }
      />
        <CustomButton
          text={"Regresar"}
          onClick={() => {
            if (activeStep === 0) {
              window.dataLayer.push({
                event: 'Rappi_validar_regresar',
                boton_contacto: "Regresar",
                paso: `Paso ${activeStep + 1}`,
              })
            }
            activeStep === 0 ? navigate("/") : handleBack()
          }
          }
          size={"medium"}
          startIcon={<ArrowBack />}
          variant={"contained"}
          style={{
            color: "var(--Rappi-neon, #FE3F23)",
            backgroundColor: "transparent",
            fontSize: "1rem",
            textTransform: "none",
            fontWeight: "600",
            fontStyle: "normal",
            padding: "8px 10px",
            minWidth: "64px",
            border: "none",
            justifyContent: "flex-start",
            width: "fit-content",
            lineHeight: 'normal'
          }}
        />
      <FormContainer
        buttonNext={buttonNextHidded}
        steps={membershipTypeModule[membershipType]?.[membershipSubtype]?.steps}
        initialValues={membershipTypeModule[membershipType]?.[membershipSubtype]?.initialValues}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        title={membershipTypeModule[membershipType]?.[membershipSubtype]?.titles}
        subTitle={membershipTypeModule[membershipType]?.[membershipSubtype]?.subtitles}
        renderStepContent={renderStepContent}
        _handleSubmit={_handleSubmit}
        validatorSchemaStepper={validatorSchemaStepper}
        formObserverKey="FormObserver"
        formObserverSlice={apiSuccess}
        localData={response}
        formikRef={getFormikReference}
      />
    </FormWrapper>
  ) : null;
};

export default SignUp;
