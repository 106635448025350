import React from "react";
import SignUpTemplate from "components/templates/SignUp"

const SignUp:React.FC = () => {

    return (
        <SignUpTemplate />
    )
}

export default SignUp;
