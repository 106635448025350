import React from 'react';
import { styled } from 'styled-components';

const breakpoints = {
  xs: "320px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1536px"
}

const devices = {
  xs: `(max-width: ${breakpoints.xs})`,
  sm: `(max-width: ${breakpoints.sm})`,
  md: `(max-width: ${breakpoints.md})`,
  lg: `(max-width: ${breakpoints.lg})`,
  xl: `(max-width: ${breakpoints.xl})`
}

const ContainerElement = styled.main`
    width: 100%;
    max-width: 1440px;
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
    @media only screen and ${devices.md} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
`;

type CustomContainerProps = {
  childComponent: React.ReactNode;
};

const CustomContainer: React.FC<CustomContainerProps> = ({ childComponent }) => {
  return (
    <ContainerElement>
      {childComponent}
    </ContainerElement>
  );
};

export default CustomContainer;
