import React from "react";

function ActiveGoldenStepIndicator() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="11"
            fill="none"
            viewBox="0 0 31 11"
        >
            <rect
                width="30"
                height="10"
                x="0.5"
                y="0.559"
                fill="#E6B331"
                rx="5"
            ></rect>
        </svg>
    );
}

export default ActiveGoldenStepIndicator;
