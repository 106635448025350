import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar, Toolbar
} from '@mui/material';
import BasicModal from '../../molecules/BasicModal/BasicModal';
import MenuContent from './MenuContent/MenuContent';
import CustomContainer from '../Container/Container';
import { useLocation } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
interface NavbarProps {
  // Define las propiedades del componente aquí (si es necesario)
}

const blackListPaths: string[] = ['/elegir-cobertura', '/resumen-membresia', '/crear-cuenta', '/redirect'];

const Navbar: React.FC<NavbarProps> = () => {
  const { pathname } = useLocation();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [isAuthState, setIsAuth] = React.useState<boolean>(false);
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  useEffect(() => {
    window.dataLayer.push({
      event: 'Rappi_page_v',
      page_title: document.title,
      page_location: window.location.href,  
    })
  }, [window.location.href])

  const MENUBODY = [
    {
      item: 'Coche Seguro',
      url: 'https://cocheseguro.inter.mx/'
      // subItems: [
      //   { label: 'Medico Seguro', onClick: () => window.open('https://medicoseguro.inter.mx/inicio', '_blank') },
      //   { label: 'Mascota Segura', onClick: () => window.open('https://mascotasegura.inter.mx/seguro-mascota/landing', '_blank') }
      // ]
    },
    {
      item: 'Médico Seguro',
      url: 'https://medicoseguro.inter.mx/inicio'
    },
    {
      item: 'Mascota Segura',
      url: 'https://mascotasegura.inter.mx/seguro-mascota/landing'
    },
    {
      item: 'Conocer más',
      subItems: [
        { label: 'Promociones', onClick: () => {
          window.dataLayer.push({
            event: 'Rappi_menu',
            option: 'Promociones',
          })    
          window.open('https://inter.mx/promotions', '_blank')
        } },
        { label: 'ABC de los seguros', onClick: () => {
          window.dataLayer.push({
            event: 'Rappi_menu',
            option: 'ABC de los seguros',
          })    
          window.open('https://inter.mx/blog', '_blank')
        } },
        { label: 'Preguntas frecuentes', onClick: () => { 
          window.dataLayer.push({
            event: 'Rappi_menu',
            option: 'Preguntas frecuentes',
          })    
          navigate('/preguntas-frecuentes')
        } }
    ]
    },
    // {
    //   item: 'Promociones',
    //   url: 'https://inter.mx/promotions'
    // },
    // {
    //   item: 'ABC de los seguros',
    //   url: 'https://inter.mx/blog'
    // },
    // {
    //   item: 'Preguntas frecuentes',
    //   onClick:() => navigate('/preguntas-frecuentes')
    //   // url: 'https://inter.mx/preguntas-frecuentes'
    // },
  ]

  return (
    <React.Fragment>
      <AppBar component="nav" position="fixed" style={{ background: '#FFFDFE', zIndex: 2000 }}>
        <CustomContainer childComponent={<MenuContent isAuthState={isAuthState} setOpenModal={setOpenModal} body={MENUBODY} visibleMenu={!blackListPaths.includes(pathname)} />} />
        <BasicModal open={openModal} setOpenModal={setOpenModal} disableBackdropClick={false} />
      </AppBar>
      <Toolbar style={{
        width: 60
      }} />
    </React.Fragment>
  );
};

export default Navbar;
