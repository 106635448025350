import { imei } from '@form-validation/validator-imei';

export const isAValidIMEI = (imeiValue: string) => {
    const res = imei().validate({
        value: imeiValue,
        options: {
            message: 'IMEI inválido',
        },
    });

    return res.valid;
}

export const onlyNumbers = (value: string ) => {
    const regex = /^[0-9\b]*$/;
    
    return regex.test(value)
}

export const onlyNumbersandCharacters = (value: string ) => {
    const regex = /^[a-zA-Z0-9]*$/;
    
    return regex.test(value)
}
