import React, { useState, useEffect } from 'react';
import { CustomTypography } from '../Label/Label';
import { CustomButton } from '../Buttons/Buttons';
import { useMediaQuery } from '@mui/material';
import { useFormikContext } from 'formik';
import { IStorageMembershipData } from 'utils/localStorageKeys';
import { useSelector } from 'react-redux';

type DecrementTimerProps = {
  initialTime: number;
  sendCodeNumber: any;
  setNum: any;
}
/**
 *
 * @param initialTime son 60 seg por minuto en este caso 2 minutos(120 segundos)
 * @localStorage aunque cierre el tab este no sera destruido hasta terminar el temporizador
 * @returns el tiempo que falta
 */
const DecrementTimer: React.FC<DecrementTimerProps> = ({ sendCodeNumber, initialTime, setNum }) => {
  const [timeRemaining, setTimeRemaining] = useState<number>(initialTime);
  const matches = useMediaQuery('(min-width:600px)');
  const formik = useFormikContext();

  useEffect(() => {
    const storedTimeRemaining = localStorage.getItem('countdownTimeRemaining');
    if (storedTimeRemaining) {
      /**
       * Reiniciamos el contador para re enviar la solicitud del numero
       * verificar si necesitamos un contador de intentos
       */
      setTimeRemaining(Number(initialTime));
    }

    const decrementTime = () => {
      setTimeRemaining((prevTime) => {
        const newTime = prevTime > 0 ? prevTime - 1 : 0;
        localStorage.setItem('countdownTimeRemaining', String(newTime));
        return newTime;
      });
    };

    const intervalId = setInterval(decrementTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
    /** eslint-disable-next-line react-hooks/exhaustive-deps**/
  }, []);

  let storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.currentValue.response),
  };

  const userResponse = useSelector((state: any) => state.formRegister.response);


  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  

  return (
    <React.Fragment>
      <CustomTypography
        style={{
          fontSize: 14,
          fontStyle: 'normal',
          textAlign: 'center',
          fontWeight: 400,
          maxWidth: 328,
          lineHeight: 'normal',
          width: '100%',
          display: 'flex',
          justifyContent: !matches ? 'flex-end' : 'center',
          gap: 6
        }}
      >
        {timeRemaining === 0 ?
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}> ¿No recibiste el código? <CustomButton size='small' style={{ color: '#FE3F23', fontSize: 14, fontWeight: '400', letterSpacing: '0.5px', textTransform: 'none' }} color="primary" onClick={() => {
            sendCodeNumber({event: "obtain-code"});
            setTimeRemaining(initialTime);
            formik.setFieldValue("otp", "")
            window.dataLayer.push({
              event: 'Rappi_validar_codigo_volver_enviar',
              boton_contacto: 'Volver a enviar',
              correo: userResponse?.email,
              tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
            });

            setNum(["", "", "", "", "", ""])
          }} text='Volver a enviar'></CustomButton> </div>
          :
          <> Podrás generar un nuevo código en <b>{formatTime(timeRemaining)}</b> </>}
      </CustomTypography>
    </React.Fragment>
  );
};

export default DecrementTimer;
