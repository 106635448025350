import React from "react";
import FormItemGenerate from "components/atoms/FormItems/FormItemGenerate";
import {useField, useFormikContext} from "formik";

type ValidateInfoProps = {
    handleChange?: any;
    handleBlur?: any;
}
const ValidateInfo: React.FC<ValidateInfoProps> = ({handleChange, handleBlur }) => {
    const [email, emailMeta] = useField("email");
    const formik = useFormikContext();

    const items = [{
        id: 1,
        label: 'Correo Electrónico',
        name: 'email',
        as: 'textField',
        error: email,
        meta: emailMeta,
        autofocus: true
    }]

    return(
        <React.Fragment>
            <FormItemGenerate
                items={items}
                handleChange={handleChange}
                handleBlur={handleBlur}
            />
        </React.Fragment>
    )
}

export default ValidateInfo
