import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import './styles.css';
import React, {useState, useEffect} from 'react';
import MemberShipCard from 'components/organisms/MembershipCard/MemberShipCard';
import Paper from '@mui/material/Paper';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import ActiveStepIndicator from './ActiveStepIndicator';
import DesactiveStepIndicator from './DesactiveStepIndicator';
import useFetch from '../../../hooks/useFetch';
import {apiLoading, apiSuccess, apiError} from '../../../api/productsSlice'
import ActiveGoldenStepIndicator from './ActiveGoldenStepIndicator';
import {MembershipKey, MembershipPlans, MembershipPlansInfo, MembershipType, MembershipTypes} from "utils/Constants";
import MembershipButton
    from "components/organisms/Membership/MembershipCoverage/MembershipDetailsCards/MembershipButtons";
import {useSelector} from "react-redux";
import {array} from "yup";
import {isObject} from "formik";

export interface IMemberShipData {
    title: string;
    descriptions: Array<string>;
}

export interface IMemberShipBenefits {
    title: string;
    icon:string
    description:string
    benefit_id:string
}

export interface IMemberShipExtra {
    title: string;
    icon:string
    description:string
}

interface Membership {
    title?: string;
    description?: string;
    image?: string;
    typeCurrent: MembershipType;
    category?: string;
}

const MemberShipSlider: React.FC<Membership> = ({title, typeCurrent}) => {
    const {fetchData} = useFetch();

    const url: string = '/catalogs/rappi-continuity';

    const fetchingData = () => {
        return fetchData(url, 'GET', {}, {}, apiLoading, apiSuccess, apiError);
    };

    const response = useSelector((state: any) => state.membershipProducts.response) as  | any  | null;
    const loading = useSelector((state: any) => state.membershipProducts.loading);

    type Producto = {
        plan_id:string,
        subtype:string,
        type: string;
        category: string;
        price:number,
        extra:Array<IMemberShipExtra>
        benefits: Array<IMemberShipBenefits>;
    };

    let [dataApi, setDataApi] = useState<Producto[]>([]);

    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    const {breakpoints} = useTheme();

    const isMobile = useMediaQuery(breakpoints.down('sm'));

    const [isActive, setIsActive] = useState(true);

    const maxStepss = dataApi.filter(
        membership => membership.type === 'free' && membership.category === "ingresos"
    ).length;

    const maxStepssPremium = dataApi.filter(
        membership => membership.type === 'premium' && membership.category === "ingresos"
    ).length;

    const freeMemberships = dataApi.filter(
        membership => membership.type === 'free' && membership.category === "ingresos"
    );

    const premiumMemberShips = dataApi.filter(
        membership => membership.type === 'premium' && membership.category === "ingresos"
    );

    let [type, setType] = useState<MembershipType>('free');

    const [dinamycType, setDinamycType] = useState('');

    useEffect(() => {
        setDinamycType('free');
    }, []);

    useEffect(() => {
        fetchingData();
    }, []);


    const generateArray =(objects:any)=>{
        let new_array: Array<Producto> = []
        Object.keys(objects).forEach(function(key, index) {
            if (isObject(objects[key])){
                new_array.push(objects[key]);
            }
        });
        return new_array;
    }

     useEffect(() => {
         if (response != null && response?.code === 200) {
                  const data = response.data
             setDataApi(generateArray(data));
         }

         }, [response, loading]);

    const currentIndicatorValue = () => {
        if (dinamycType === 'premium') {
            return maxStepssPremium;
        } else if (dinamycType === 'free') return maxStepss;
    };

    const currentMemberShipsValues = () => {

        if (dinamycType === 'premium') {
            return premiumMemberShips
        }
        return freeMemberships
    };

             const onClickHandlerModule = (value: MembershipType) => {
                 setDinamycType(value);
                 setType(value);
                 setIsActive(!isActive);
             };

             const theme = useTheme();
             const [activeStep, setActiveStep] = React.useState(0);

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

             const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <div id='my-section'>
            <Box
                id='my-section'
                sx={{
                    backgroundColor: '#FFF',

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Paper
                    square
                    elevation={0}
                    sx={{
                        width: isDesktop ? '100%' : 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '24px',
                        pl: 0,
                        bgcolor: 'background.default',
                    }}
                >
                    <Typography
                        color={'var(--black, #212121)'}
                        fontSize={'24px'}
                        fontWeight={700}
                        style={{
                            cursor: 'pointer',
                        }}
                        textAlign={'center'}
                    >
                        {title}
                    </Typography>
                    {title && (
                        <div className='container-gallery-buttons'>
                            <MembershipButton
                                newStyle={{
                                    width: '100%'
                                }}
                                type={type}
                                onClickSubType={onClickHandlerModule}
                            />
                        </div>
                    )}
                </Paper>

                {isMobile ? (
                    <AutoPlaySwipeableViews
                        interval={500000000}
                        axis={theme.direction === 'rtl' ? 'x' : 'x'}
                        index={activeStep}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            // maxWidth: '400px',
                            minWidth: '283px',

                            paddingTop: '24px',
                            overflow: 'hidden',
                            // width: '100%',
                        }}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {


                            currentMemberShipsValues().map((card, id) => {
                            if (dinamycType === card.type) {
                                return (
                                    <MemberShipCard
                                        key={id}
                                        idCard={id}
                                        plan={card}
                                        cardType={card.type}
                                    />

                                );
                            }
                        })}
                    </AutoPlaySwipeableViews>
                ) : (
                    <Box
                        style={{
                            width: '80%',
                            margin: '0 auto'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingTop: '40px',
                                gap: '10px',
                                alignItems: 'flex-start'
                            }}
                        >
                            {currentMemberShipsValues().map((card, id) => {
                                if (dinamycType === card.type) {
                                    return (
                                        <MemberShipCard
                                            key={id}
                                            idCard={id}
                                            plan={card}
                                            cardType={card.type}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </Box>
                )}

                {isMobile && false ? (
                    <div className='indicator-slider-container'>
                        {[...Array(currentIndicatorValue())].map((step, index) => {
                            return (
                                <span
                                    key={index}
                                    onClick={() => setActiveStep(index)}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '0 5px',
                                    }}
                                >
                  {index === activeStep ? (
                      <>
                          {dinamycType === 'premium' ? (
                              <ActiveGoldenStepIndicator/>
                          ) : (
                              <ActiveStepIndicator/>
                          )}
                      </>
                  ) : (
                    <DesactiveStepIndicator/> || 'indicator'
                  )}
                </span>
                            );
                        })}
                    </div>
                ) : (
                    <div></div>
                )}
            </Box>
        </div>
    );
};

export default MemberShipSlider;
