import React, {useEffect, useState} from 'react';
import 'components/organisms/MeetOurMemberships/styles.css';
import VectorBackgroundIcon from './assets/VectorBackgroundIcon';
import SmarthPhoneIcon from './assets/SmarthPhoneIcon';
import celular from './assets/SmarthPhoneFull.svg';
import HeartIcon from './assets/HeartIcon';
import {CustomButton} from '../../atoms/Buttons/Buttons';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {apiSuccess as setCurrentValue} from '../../../api/currentValueSlice';
import {IMemberShipData} from 'components/organisms/MeetOurMemberships/MembershipSlider';
import {styled} from 'styled-components';

import LoadingComponent from 'components/organisms/loading/loading';

//Restart tracking_id with all the register proccess / cleaning the form fields
//import { restartValue as restartBrand } from "../../../api/gadgetsBrandSlice";
//import { restartValue as restartModels } from "../../../api/gadgetsModelsSlice";
//import { restartValue as restartDescription } from "../../../api/gadgetsDescriptionSlice";
//import { restartValue as restartLeads } from "../../../api/leadsSlice";
//import { restartValue as restartUser } from "../../../api/userSlice";
//import { restartValue as restartSignUp } from "../../../api/signUpSlice";
import {restartValue as restartRegister} from "../../../api/formRegisterSlice";
//import { fetchTrackingStore } from '../../../api/fetchingTrackingStore';
//import { getBrowserInfo } from '../../../utils/navigatorDetails';
import {
    apiSuccess as successTracking,
    apiError as errorTracking,
    apiLoading as loadingTracking,
} from "../../../api/trackingSlice";
import useFetch from '../../../hooks/useFetch';
import {MembershipKey, MembershipKeyTypes, MembershipPlansInfo} from "utils/Constants";
import {CustomTypography} from "components/atoms/Label/Label";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckIcon from "@mui/icons-material/Check";
import {fetchTrackingStore} from 'api/fetchingTrackingStore';
import {getBrowserInfo} from 'utils/navigatorDetails';

const TitleElement = styled.span`
  color: var(--black, #212121);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const SubTitleElement = styled.span`
  color: var(--black, #212121);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

type CustomContainerProps = {
    idCard: number;
    cardTitle?: string;
    plan: MembershipPlansInfo,
    cardDescription?: string;
    cardImageTitle?: string;
    cardType?: string;
    cardCategory?: string;
    childComponent?: React.ReactNode;
    cardData?: Array<IMemberShipData>;
    cardSubtype?: any;
};

const MemberShipCard: React.FC<CustomContainerProps> = ({

                                                            idCard,
                                                            cardTitle,
                                                            cardDescription,
                                                            cardType,
                                                            plan,
                                                            cardData,
                                                            cardSubtype,
                                                            cardCategory
                                                        }) => {
    const navigatePage = useNavigate()

    const dispatch = useDispatch();

    const isIphone5 = useMediaQuery('(min-device-width: 0px) and (max-device-width: 320px) and (min-device-height: 0px) and (max-device-height: 568px) ');
    const sendDataredux = {
        type: cardType,
        subtype: cardType === 'free' ? "freemium" : "premium",
        category: cardCategory,
        cardTitle: cardTitle,
        cardDescription: cardDescription
    };

    //const ResponseHealth = useSelector((state: any) => state.health.response) as any | null;
    //const LoadingHealth = useSelector((state: any) => state.health.loading);
    //const ErrorHealth = useSelector((state: any) => state.health.error);

    //const ResponseGadgets = useSelector((state: any) => state.gadgets.response) as any | null;
    //const LoadingGadgets = useSelector((state: any) => state.gadgets.loading);
    //const ErrorGadgets = useSelector((state: any) => state.gadgets.error);

    const [Gadgets, setGagets] = useState<MembershipPlansInfo>();


    const {fetchData} = useFetch();

    useEffect(() => {
        const subType: MembershipKey = cardType === "premium" ? "premium" : "freemium";
        //setGagets(ResponseGadgets?.data[subType]);
        //setHealth(ResponseHealth?.data[subType]);
        //setData(cardCategory === "salud" ? ResponseHealth?.data : ResponseGadgets?.data);
    }, [/*ResponseHealth, ResponseGadgets*/]);

    // useEffect(() => {
    // }, [Gadgets, Health])

    useEffect(() => {
        const subType: MembershipKey = cardType === "premium" ? "premium" : "freemium";
        setGagets(plan);
        //setHealth(ResponseHealth?.data[subType]);
    }, [cardType])


    //alert(JSON.stringify(Gadgets))

    const restartRegisterForm = () => {
        // dispatch(restartBrand());
        //  dispatch(restartModels());
        //  dispatch(restartLeads());
        //  dispatch(restartDescription());
        //  dispatch(restartUser());
        //  dispatch(restartSignUp());
        dispatch(restartRegister());
        // sessionStorage.removeItem("exitoPremium");
        fetchTrackingStore(
            "users/tracking",
            "create-tracking",
            {
                content: {
                    data: {},
                    ...getBrowserInfo(),
                },
            },
            fetchData,
            loadingTracking,
            successTracking,
            errorTracking
        );
    };


    const eventFiunction = () => {
        window.dataLayer.push({
            event: 'Rappi_nuestros_seguros_loquiero',
            tipo_seguro: cardType == "free" ? "De regalo" : "Premium",
            boton_contacto: 'Lo quiero'
        })
        const sendDataredux = {
            type: "rappi",
            subtype: Gadgets?.subtype,
            memberShipProduct:Gadgets
        }

        restartRegisterForm();
        dispatch(setCurrentValue(sendDataredux));
        navigatePage("/registrate/1")
    }

    const matches = useMediaQuery('(max-width:320px)');

    return (
        <Box sx={{
            width: '100%',
            maxWidth: matches ? '323px' : '428px',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <LoadingComponent isLoaded={Gadgets != undefined ? true : false}/>
            {Gadgets != undefined ? <div className={`slider`}
                                         id='identifier-123'
                                         style={{
                                             display: Gadgets != undefined ? 'flex' : 'none',
                                             width: '100%',
                                             margin: isIphone5 ? 'auto 10px' : 'auto',
                                             height: 'fit-content',
                                         }}
            >
                <Box sx={{
                    display: "flex",
                    width: '100%',
                    padding: "0px 0px 32px 0px",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "40px",
                    alignSelf: "stretch",
                    borderRadius: "8px",
                    border: "1px solid var(--Neon-Gradient, #F3663D)",
                    background: "var(--White, #FFF)",
                    boxShadow: "4px 6px 16px 0px rgba(33, 33, 33, 0.05)",
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: 'center',
                        borderRadius: "8px 8px 0px 0px",
                        background: "var(--Beige, #FFF0DD)",
                        width: "100%",
                        padding: "24px 0px",
                        gap: "8px",
                    }}>
                        <CustomTypography
                            item={
                                cardType === "free" ?
                                    'PLAN DE REGALO'
                                    :
                                    'PLAN PREMIUM'
                            }
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignSelf: "stretch",
                                color: "var(--Black, #212121)",
                                textAlign: "center",
                                fontFamily: "Montserrat",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "normal",
                            }}
                        />

                        <CustomTypography
                            item={`$${Gadgets?.price} / mes`}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignSelf: "stretch",
                                color: "var(--Rappi-neon, #FE3F23)",
                                textAlign: "center",
                                fontFamily: "Montserrat",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "normal",
                            }}
                        />
                    </Box>

                    <Box>
                        <CustomTypography
                            item={"Beneficios incluídos"}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignSelf: "stretch",
                                color: "var(--black-75, #595959)",
                                textAlign: "center",
                                fontFamily: "Montserrat",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "normal",
                            }}
                        />
                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: "60px",
                            height: "100%",
                        }}>
                            <CheckIcon style={{color: '#5AB52F', fontSize: '27px',}}/>
                        </Box>
                        <Box sx={{
                            padding: '0 7px 0 0px',
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            gap: '14px',
                        }}>

                            <CustomTypography
                                item={Gadgets.benefits.length > 0 ? Gadgets.benefits[0].title : ""}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignSelf: "stretch",
                                    color: "var(--Black, #212121)",
                                    fontFamily: "Montserrat",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                }}
                            />
                            <CustomTypography
                                item={Gadgets.benefits.length > 0 ? Gadgets.benefits[0].description : ""}

                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignSelf: "stretch",
                                    color: "var(--Black, #212121)",
                                    fontFamily: "Montserrat",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                }}
                            />
                        </Box>


                    </Box>

                    <Box>
                        <CustomTypography
                            item={Gadgets.extra.length > 0 ? Gadgets.extra[0].title : ""}

                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignSelf: "stretch",
                                color: "var(--black-75, #595959)",
                                textAlign: "center",
                                fontFamily: "Montserrat",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "normal",
                            }}
                        />
                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: "60px",
                            height: "100%",
                        }}>
                            <CheckIcon style={{color: '#5AB52F', fontSize: '27px',}}/>
                        </Box>
                        <Box sx={{
                            padding: '0 7px 0 0px',
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            gap: '14px',
                        }}>

                            <CustomTypography
                                item={Gadgets.extra.length > 0 ? Gadgets.extra[0].description : ""}

                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignSelf: "stretch",
                                    color: "var(--Black, #212121)",
                                    fontFamily: "Montserrat",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                }}
                            />
                        </Box>
                    </Box>

                    <div style={{
                        display: "flex",
                        padding: "14px 16px  16px 16px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        alignSelf: "stretch",
                        borderRadius: "20px",

                    }}>

                        <CustomButton
                            text={"¡Lo quiero!"}
                            onClick={() => eventFiunction()}
                            size={'medium'}
                            variant={'contained'}

                            style={{
                                fontWeight: 600,
                                borderRadius: 20,
                                boxShadow: 'none',
                                background: 'linear-gradient(180deg, #1FD6B3 0%, #23ACB7 100%)',
                                padding: "0px",
                                maxWidth: "580px",
                                border: "none",
                                justifyContent: "center",
                                width: "100%",
                                height: "48px",
                                color: "var(--White, #FFF)",
                                textAlign: "center",
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                textTransform: "uppercase",
                            }}
                        />
                    </div>

                </Box>

            </div> : ''
            }
        </Box>
    );
};

export default MemberShipCard;
