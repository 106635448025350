import React from 'react';
import styled from 'styled-components';
import { CustomTypography } from 'components/atoms/Label/Label';
import { Button, useMediaQuery } from '@mui/material';
import { CustomButton } from 'components/atoms/Buttons/Buttons';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { IStorageMembershipData } from 'utils/localStorageKeys';

const LayOutContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-top: 40px;
    padding-bottom: 85px;
    width: 100%;
    max-width: 558px;
    margin: auto;
  `;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-left: 24px;
  padding-right: 24px;
  gap: 40px;
  align-items: center;
  max-width: 558px;
  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const WeSorry = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const userResponse = useSelector((state: any) => state.formRegister.response);
    let storage: IStorageMembershipData = {
        ...useSelector((state: any) => state.currentValue.response),
    };

    React.useEffect(() => {
        window.dataLayer.push({ 
            event: 'Rappi_no_disponible_v',
            tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
            correo: userResponse?.email, 
            telefono: undefined,
            page_location: window.location.href, 
        });        
    }, []);
    const navigate = useNavigate();
    return(
        
        <LayOutContainer>
            <Wrapper>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 16
                }}>
                    <CustomTypography
                        style={{
                        fontSize: matches ? 28 : 24,
                        fontWeight: 700,
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        Padding: "0 1rem",
                        textWrap: "balance",
                        }}
                    >
                        Por el momento no podemos ofrecerte el plan de protección
                    </CustomTypography>
                    
                    <CustomTypography
                        style={{
                        fontSize: matches ? 24 : 18,
                        fontWeight: 400,
                        letterSpacing: "0.5px",
                        textAlign: "center",
                        Padding: "0 1rem",
                        textWrap: "balance"
                        }}
                    >
                        Conviértete a Nivel Silver para disfrutar de los beneficios
                    </CustomTypography>
                    <CustomButton
                        text={"Más información"}
                        onClick={() => {
                        }
                        }
                        size={"medium"}
                        variant={"contained"}
                        style={{
                            color: "var(--Rappi-neon, #FE3F23)",
                            backgroundColor: "transparent",
                            fontSize: "1rem",
                            textTransform: "none",
                            fontWeight: "600",
                            fontStyle: "normal",
                            padding: "8px 10px",
                            minWidth: "64px",
                            border: "none",
                            justifyContent: "flex-start",
                            width: "fit-content",
                            lineHeight: 'normal'
                        }}
                    />
                </div>
                <Button
                        type="submit"
                        size={"medium"}
                        variant={"contained"}
                        onClick={() => {
                            window.dataLayer.push({
                                event: 'Rappi_no_disponible',
                                boton_contacto: 'Entendido',
                                correo: userResponse?.email,
                                tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
                            });
                            navigate('/');
                        }
                        }
                        style={
                        {
                            borderRadius: 20,
                            width: "100%",
                            padding: "14px 16px",
                            background: "var(--Rappi-neon, #FE3F23)",
                            color: "#FFF",
                            opacity: '0.8',
                            fontSize: 16,
                            fontWeight: 600,
                            textTransform: 'uppercase',
                            lineHeight: 'normal'
                        }
                        }
                    >
                        Entendido
                    </Button>
            </Wrapper>
        </LayOutContainer>
    )
}

export default WeSorry;
