import { combineReducers, configureStore } from '@reduxjs/toolkit';
import apiReducer from '../api/apiSlice';
import apiFormRegister from '../api/formRegisterSlice';
import storage from 'redux-persist/lib/storage';
import currentValueSlice from '../api/currentValueSlice';
import validateCodeSlice from "api/validateCodeSlice";
import trackingSlice from 'api/trackingSlice';
import productsSlice from 'api/productsSlice';
import leadsSlice from 'api/leadsSlice';
import validateUserSlice from 'api/validateUserSlice';
import userRappiSlice from 'api/userRappiSlice';
import signUpSlice from 'api/signUpSlice';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import trackingIdSlice from "api/trackingIdSlice";

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [ "validateUser", "signUp" ]
}

const reducer = combineReducers({
  // aqui asignamos nuestros reducers(archivos) api/*Slice
  api: apiReducer,
  formRegister: apiFormRegister,
  currentValue: currentValueSlice,
  validateCode: validateCodeSlice,
  tracking: trackingSlice,
  membershipProducts: productsSlice,
  leads: leadsSlice,
  validateUser: validateUserSlice,
  userRappi: userRappiSlice,
  signUp: signUpSlice,
  trackingId: trackingIdSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store: any = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(store);

