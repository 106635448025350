import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
    apiSuccess as apiTrackingSuccess
} from '../../api/trackingSlice';
import {
    apiSuccess as apiRedirectTrackingIdSuccess
} from '../../api/trackingIdSlice';

import {apiLoading, apiSuccess, apiError} from '../../api/trackingIdSlice';
import {apiSuccess as apiSuccessRegister} from '../../api/formRegisterSlice';

import useFetch from '../../hooks/useFetch';

import LoadingComponent from '../../components/organisms/loading/loading';
import {fetchTrackingStore} from "api/fetchingTrackingStore";
import {addAccessToken} from "api/config";

const RedirectRappiTrackingId: React.FC = () => {
    // Implementa el componente aquí
    const location = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {fetchData} = useFetch();
    const responsegetLeads = useSelector((state: any) => state.trackingId.response) as any | null;

    const createTrackingId = () => {
        const createTrackingIdPersistant = {
            "response": {
                "code": 200,
                "ok": true,
                "msg": "ok",
                "data": {
                    "data": {},
                    "navigator": "Google Chrome Generated",
                    "RedirectTrackingIded": false,
                    "created_at": "2023-11-03T21:53:07.974Z",
                    "tracking_id": location?.id,
                    "status": false
                }
            },
            "loading": false,
            "error": null
        };

        dispatch(apiTrackingSuccess(createTrackingIdPersistant?.response))
        //fetchData(`/users/leads/get-one-lead/${location?.id}`, 'GET', {}, {}, apiLoading, apiSuccess, apiError)

        fetchTrackingStore("/users/auth", "get-user-rappi", {
                content: {
                    "tracking_id": location?.id
                },
            }, fetchData,
            apiLoading, apiSuccess, apiError
        )
    }

    useEffect(() => {
        if (location?.id) {
            createTrackingId();
        }
    }, []);

    useEffect(() => {
        if (responsegetLeads?.ok) {
            let leads = {...responsegetLeads.data};
            sessionStorage.setItem("access_token",leads?.access_token)
            dispatch(apiRedirectTrackingIdSuccess(responsegetLeads))
            dispatch(apiSuccessRegister(leads));
        }
    }, [responsegetLeads]);


    let timer: any  = null
    useEffect(() => {
        if (responsegetLeads?.ok) {
            timer = setTimeout(()=>{
                addAccessToken().then(()=>{
                    navigate(`/actualizar`);
                })
            },2000)
        }
        return ()=>{
                clearTimeout(timer)
        }
    }, [responsegetLeads]);

    return (
        <React.Fragment>
            <LoadingComponent
                isLoaded={!true}
                interTextMessage={'Te estamos redirigiendo a tu pagina'}
            />
        </React.Fragment>
    );
};

export default RedirectRappiTrackingId;
