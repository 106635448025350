import React from "react";
import NotFoundTemplate from "components/templates/NotFound"

const NotFound:React.FC = () => {

    return (
        <NotFoundTemplate />
    )
}

export default NotFound;
