import React, {useEffect, useState} from "react";
import {
    Field, Formik, Form,
    useFormikContext, useField
} from 'formik';
import {
    Box,
    Checkbox, useMediaQuery
} from '@mui/material';
import {CustomButton} from "components/atoms/Buttons/Buttons";
import FormItemGenerate from "components/atoms/FormItems/FormItemGenerate";
import {CustomTypography} from "components/atoms/Label/Label";
import InfoCheck from "assets/RegisterAssets/InfoCheck";
import { useSelector } from "react-redux";
import {MembershipPlansInfo} from "utils/Constants";

type ConfirmInfoProps = {
    errors: any;
    handleChange?: any;
    handleBlur?: any;
}

enum SubtypeCurrentValue {
    freemium = 'freemium',
    premium = 'premium'
}

const PlanDetail = (data?:any) => {

    const currentValue = useSelector((state: any) => state.currentValue.response) as
    | any
    | null;
    const membershipSubtype: SubtypeCurrentValue = currentValue?.subtype;
    const MemberShipProduct: MembershipPlansInfo = currentValue?.memberShipProduct;
    const subType = membershipSubtype;

    return (<Box sx={{
        display: "flex",
        width: '100%',
        padding: "0px 0px 32px 0px",
        flexDirection: "column",
        alignItems: "center",
        gap: "40px",
        alignSelf: "stretch",
        borderRadius: "8px",
        border: "1px solid var(--Neon-Gradient, #F3663D)",
        background: "var(--White, #FFF)",
        boxShadow: "4px 6px 16px 0px rgba(33, 33, 33, 0.05)",
    }}>
        <Box sx={{
            display: "flex",
            width: "100%",
            padding: "16px 0px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: 'center',
            gap: '8px',
            borderRadius: "8px 8px 0px 0px",
            background: "var(--Beige, #FFF0DD)",
        }}>
            <CustomTypography
                item={subType==="freemium"?  'PLAN DE REGALO'
                    :
                    'PLAN PREMIUM'}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignSelf: "stretch",
                    color: "var(--Black, #212121)",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                }}
            />

            <CustomTypography
                item={`$${MemberShipProduct?.price} / mes`}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignSelf: "stretch",
                    color: "var(--Rappi-neon, #FE3F23)",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                }}
            />
        </Box>

        <Box>
            <CustomTypography
                item={"Beneficios"}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignSelf: "stretch",
                    color: "var(--black-75, #595959)",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                }}
            />
        </Box>

        <Box sx={{display:'flex',flexDirection:'row'}}>
            <Box sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                width: "60px",
                height: "100%",
            }}>
                <InfoCheck/>
            </Box>
            <Box sx={{
                padding:'0 7px 0 0px',
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: '14px',
            }}>

                <CustomTypography
                    item={MemberShipProduct?.benefits?.length >0 ? MemberShipProduct?.benefits[0].title: ""}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignSelf: "stretch",
                        color: "var(--Black, #212121)",
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "normal",
                    }}
                />
                <CustomTypography
                    item={MemberShipProduct?.benefits?.length >0 ? MemberShipProduct?.benefits[0].description: ""}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignSelf: "stretch",
                        color: "var(--Black, #212121)",
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                    }}
                />
            </Box>


        </Box>

    </Box>)
}

const ConfirmInfo: React.FC<ConfirmInfoProps> = ({errors, handleChange, handleBlur}) => {
    const matches = useMediaQuery('(min-width:600px)');
    const formik = useFormikContext();
    const userRappi = useSelector((state: any) => state.userRappi.response) as
    | any
    | null;

    const userUpdate = useSelector((state: any) => state.trackingId.response) as
        | any
        | null;
    const [name, nameMeta] = useField("fullName");
    const [phone, phoneMeta] = useField("phone");

    const tyc = async () => {
        let anchor = document.createElement('a');
        anchor.href = 'https://teregalamosunseguro.inter.mx/terminos-y-condiciones';
        anchor.target = "_blank";
        anchor.click();
    }
    const aviso = async () => {
        let anchor = document.createElement('a');
        anchor.href = 'https://inter.mx/aviso-de-privacidad';
        anchor.target = "_blank";
        anchor.click();
    }

    useEffect(() => {
        if(userRappi?.rappi_id){
            formik.setFieldValue("fullName", userRappi.name)
            formik.setFieldValue("phone", userRappi.phone)
        }
        if (userUpdate){
            formik.setFieldValue("fullName", userUpdate?.data?.name)
            formik.setFieldValue("phone", userUpdate?.data?.phone)
        }

    }, [userUpdate]);

    const items = [
        {
            id: 1,
            label: 'Nombre completo',
            name: 'fullName',
            as: 'textField',
            error: name,
            meta: nameMeta,
            disabled: true
        },
        {
            id: 2,
            label: 'Celular',
            name: 'phone',
            as: 'textField',
            error: phone,
            disabled: true,
            meta: phoneMeta
        },

    ]


    return (<React.Fragment>

        <Box sx={{
            display: 'flex',
            width: '100%',
        }}>
            <CustomTypography
                item={"Tus datos personales"}
                style={{
                    fontSize: 18,
                    fontStyle: "normal",
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: !matches ? 'flex-end' : 'center',
                    fontWeight: 700,
                    color: '#595959',
                    maxWidth: '100%',
                }}
            />
        </Box>

        <FormItemGenerate
            items={items}
            handleChange={handleChange}
            handleBlur={handleBlur}
        />
        <Box sx={{
            width: "100%",
            height: "1px",
            background: "#C7C7C7",

        }}/>

        <Box sx={{
            display: 'flex',
            width: '100%',
        }}>
            <CustomTypography
                item={"Resumen del plan"}
                style={{
                    fontSize: 18,
                    fontStyle: "normal",
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: !matches ? 'flex-end' : 'center',
                    fontWeight: 700,
                    color: '#595959',
                    maxWidth: '100%',
                }}
            />
        </Box>

        <PlanDetail />

        <label style={{width: '100%', display: 'flex', flexDirection: 'column', gap: 0, alignItems: 'flex-start'}}>
            <span style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 8
            }}>
                <Field
                    className='error-message'
                    as={Checkbox}
                    type="checkbox"
                    name="acceptTerms"
                    style={{padding: 0, top: '3px', color: '#FE3F23'}}
                    onChange={(ev: any) => {
                        formik.setFieldValue('acceptTerms', ev.target.checked)
                    }
                    }
                />
                <span style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: 5,
                    alignItems: 'center',
                    fontSize: 16,
                    top: 3,
                    position: 'relative'
                }}>
                    <span style={{
                    }}>Acepto</span>
                    <CustomButton
                        style={{

                            textTransform: 'inherit',
                            fontStyle: 'normal',
                            color: "var(--Rappi-neon, #FE3F23)",
                            padding: 0,
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: 'normal',
                            textDecoration: 'underline'
                        }}
                        onClick={() => tyc()}
                        text='Términos y Condiciones'
                    />
                    <span style={{}}> y el </span>
                    <CustomButton
                        style={{
                            textTransform: 'inherit',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            color: "var(--Rappi-neon, #FE3F23)",
                            padding: 0,
                            fontSize: '16px',
                            lineHeight: 'normal',
                            textDecoration: 'underline'
                        }}
                        onClick={() => aviso()}
                        text='Aviso de privacidad'
                    />
                </span>
            </span>
            {errors?.acceptTerms && <span className='error-message'>{errors.acceptTerms}</span>}
        </label>
        {/*} <ReCAPTCHA
            sitekey="6LdVLtUjAAAAAK63bwg6YPLQa1GV6_H1ibukGzz8"
            onChange={(ev: any) => {
                handleChange(ev);
                if(ev){
                    formik.setFieldValue('recaptcha', true)
                }
            }}
            hl="es"
        />*/}

    </React.Fragment>)
}

export default ConfirmInfo;
