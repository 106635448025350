import React from 'react';
import RappiFAQsTemplate from '../../components/templates/RappiFAQs';
import CustomContainer from '../../components/organisms/Container/Container';

const RappiFAQs: React.FC = () => {
  // Implementa el componente aquí
  return (
    <React.Fragment>
        <CustomContainer childComponent={ <RappiFAQsTemplate />} />
    </React.Fragment>

  );
};

export default RappiFAQs;
