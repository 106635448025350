import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import {styled} from "styled-components";
import {useFormikContext} from "formik";

import validation from "assets/gifs/signaturegif.gif";
import errorGift from "assets/gifs/errorgif.gif";
import {CustomTypography} from "components/atoms/Label/Label";
import {CustomButton} from "components/atoms/Buttons/Buttons";
import DecrementTimer from "components/atoms/DecrementTimer";
import useFetch from "hooks/useFetch";
import {useDispatch, useSelector} from "react-redux";
import {
    apiLoading,
    apiSuccess,
    apiError,
    clearSuccess
} from 'api/validateCodeSlice';
const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
`;

const OTPElement = styled(TextField)`
  display: flex;
  max-width: 48px;
  height: 60px;
  padding: 14px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #1FD8B5;
  justify-content: center;
`;

type ValidateCodeOTPProps = {
    handleChange: () => void;
};
type CodeNumberProps = { event: string };

const ValidateCodeOTP: React.FC<ValidateCodeOTPProps> = () => {
    const {fetchData} = useFetch();

    const [num, setNum] = useState(["", "", "", "", "", ""]);
    const [error, setError] = useState(false);
    // const errorLeads = useSelector((state: any) => state.leads.error);
    const tracking = useSelector((state: any) => state.tracking.response) as any | null;
     const code = useSelector((state: any) => state.validateCode.response) as any | null;
    const formik = useFormikContext();
    const dispatch = useDispatch();

    const [showCode, setShowCode] = useState(false)
    const [tmpCode, setTmpCode] = useState("")


    const sendCodeNumber = ({event}: CodeNumberProps) => {
         return fetchData('users/otp', 'POST', {
             "event": event,
             "content": { 
                "tracking_id": tracking?.data?.tracking_id,
                "business": "rappi" 
            }, 
         }, {}, apiLoading, apiSuccess, apiError);

    }

    useEffect(() => {
        sendCodeNumber({event: "obtain-code"});
    }, []);


    useEffect(() => {
         if (code?.ok) {
             setShowCode(true);
             setTmpCode(code?.data?.otp);
             dispatch(clearSuccess());
             formik.setFieldValue("otp", "");
         }
    }, [code]);

    const recibirOTP = (otp: any) => {
        // Dividimos el OTP en dígitos individuales
        // const digitosOTP = otp.split('');
        // Llenamos el arreglo num con los dígitos del OTP
        setNum(otp);
        formik.setFieldValue("otp", otp.join(""))
    };

    const nextFocus = (inputS: string) => {
        const nextInput = document.getElementById(inputS);
        if (nextInput) nextInput?.focus();
    }

    useEffect(() => {
        //  if(errorLeads){
        //      setError(true)
        //  }
    }, [ /*errorLeads*/]);


    return (
        <LayOutContainer>
            <img width={150} src={error ? errorGift : validation} alt="validation"/>
            <CustomTypography
                item={
                    error
                        ? "No hemos podido validar tu número, por favor revisa que tus datos sean correctos"
                        : ""
                }
                style={{
                    fontSize: 18,
                    fontStyle: "normal",
                    textAlign: "center",
                    fontWeight: 400,
                    maxWidth: 328,
                    lineHeight: "normal",
                }}
            />
            {error && <div>
              <CustomButton size='small' style={{
                  color: '#039ECC',
                  fontSize: 14,
                  fontWeight: '400',
                  letterSpacing: '0.5px',
                  textTransform: 'none'
              }} color="primary" onClick={() => {
                  //dispatch(apiLeadsError(false));
                  setError(false);
                  // formik.setFieldValue("otp", "");
                  setNum(["", "", "", "", "", ""]);
              }} text='Volver a intentarlo'></CustomButton>
            </div>}
            {!error && (
                <React.Fragment>
                    <div
                        style={{
                            display: "flex",
                            gap: 16,
                            flexDirection: "column",
                        }}
                    >
                        <span>{tmpCode}</span>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 8,
                                flexGrow: 1,
                                maxWidth: 360,
                            }}
                        >
                            {num.map((digito, index) => (
                                <OTPElement
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: "#1FD8B5",
                                            },
                                        },
                                        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#1FD8B5"
                                        },
                                    }}
                                    required
                                    id={`OTP-${index}`}
                                    key={index}
                                    type="text"
                                    value={digito}
                                    onChange={(e: any) => {
                                        const regex = /^[0-9\b]*$/;
                                        if (regex.test(e.target.value)) {
                                            const nuevosNumeros = [...num];
                                            nuevosNumeros[index] = e.target.value;
                                            recibirOTP(nuevosNumeros);
                                            if (e.target.value) {
                                                nextFocus(`OTP-${index + 1}`)
                                            }
                                        }
                                    }}
                                    onKeyDown={(e: any) => {
                                        const input = e.target;
                                        if (e.key === "Backspace" && input.value === "") {
                                            nextFocus(`OTP-${index - 1}`)
                                        }
                                    }}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        maxLength: 1,
                                        style: {textAlign: "center", fontWeight: "700"},
                                    }}
                                />
                            ))}
                        </div>
                        <DecrementTimer initialTime={120} sendCodeNumber={sendCodeNumber} setNum={setNum}/>
                    </div>
                </React.Fragment>
            )}
        </LayOutContainer>
    )
}

export default ValidateCodeOTP
