import React from "react";
import SuccessSignUpTemplate from "components/templates/SuccessSignUp"

const SuccessSignUp:React.FC = () => {

    return (
        <SuccessSignUpTemplate />
    )
}

export default SuccessSignUp;
