import Typography from "@mui/material/Typography";
import ACCIDENTE from "../assets/Landing/Accidente.svg"
import ENFERMEDAD from "../assets/Landing/Enfermedad.svg"
import {IMemberShipBenefits, IMemberShipExtra} from "components/organisms/MeetOurMemberships/MembershipSlider";

const  DESCRIPTION_UNO=
    <div>
        <Typography
            fontFamily={'Montserrat'}
            fontSize={20}
            fontStyle={'normal'}
            fontWeight={'400'}
            color={'#212121'}
            align={'center'}>
            En alianza con <b>Inter.mx</b> lanzamos un seguro que premia tu lealtad protegiendo tus ingresos.
        </Typography>
    </div>

const  DESCRIPTION_DOS=
    <div>
        <Typography
            fontFamily={'Montserrat'}
            fontSize={20}
            fontStyle={'normal'}
            fontWeight={'400'}
            color={'#212121'}
            align={'center'}
            marginTop={3}
        >
            ¡Te damos <b>$1,500 MXN</b> de regalo!
        </Typography>
    </div>

const  DESCRIPTION_TRES=(cost="")=>(
    <div>
        <Typography
            fontFamily={'Montserrat'}
            fontSize={20}
            fontStyle={'normal'}
            fontWeight={'400'}
            color={'#212121'}
            align={'center'}
            marginTop={3}
        >
            Conviértete a Premium y por menos de <b>$5 MXN</b> diarios recibe un apoyo de <b>{cost}</b>
        </Typography>
    </div>)

export const DESCRIPTION = (cost = "") => {
    let array = [
        {ID: 1, description: DESCRIPTION_UNO},
        {id: 2, description: DESCRIPTION_DOS},
        {id: 3, description: DESCRIPTION_TRES(cost)}
    ]

    return array
}

export const PROTECT =[
    {id:1,image:ACCIDENTE,titulo:"ACCIDENTE", description:"Si sufres un accidente que te incapacite por más de 7 días"},
    {id:1,image:ENFERMEDAD,titulo:"ENFERMEDAD", description:"En caso de enfermedad que no te permita continuar laborando"}
]

export const arraya = [
    {
        "image": "Celular",
        "data": [
            {
                "title": "Obtén tu seguro de regalo y protege tu equipo en caso de que:",
                "descriptions": ["Se te rompa la pantalla hasta por $4,000 pesos."]
            }, {
                "title": "Además, beneficios extras como:",
                "descriptions": ["Telemedicina general ilimitada.", "Acceso a un club de descuentos.", "Seguro de muerte accidental por $20,000 pesos."]
            }, {
                "title": "Restricciones:",
                "descriptions": ["Solo aplica para modelos que no tengan más de 3 años de antigüedad."]
            }], "type": "free", "title": "Celular Protect", "category": "celular", "plan_id": "P01"
    },

    {"image":"Celular","data":[{"title":"Por menos de $5 pesos al día protege tus dispositivos en caso de que:","descriptions":["Se te rompa la pantalla, te lo reparamos hasta por $4,000 pesos.","Sufras robo con violencia, te pagamos hasta $10,000 pesos."]},{"title":"Además, beneficios extras como:","descriptions":["Telemedicina general ilimitada.","Club de descuentos con miles de negocios afiliados","Precio preferencial en la contratación de seguro de coche, gasto médico mayor o mascota en inter.mx","Seguro de muerte accidental por $20,000 pesos."]},{"title":"Restricciones:","descriptions":["Solo aplica para modelos que no tengan más de 3 años de antigüedad. "]}],"type":"premium","title":"Celular Protect","category":"celular","plan_id":"P01"}
]

const arrayafree = [{
    "benefits": [{
        "icon": "",
        "description": "¿Se te rompió la pantalla? Te cubrimos con hasta $4,000 MXN para que la repares (1 evento al año con copago de $400 MXN).",
        "benefit_id": "C01",
        "title": "Reparación de pantalla"
    }, {
        "icon": "",
        "description": "Protege a tus seres queridos con $20,000 MXN en caso del fallecimiento.",
        "benefit_id": "C01",
        "title": "Muerte accidental"
    }],
    "subtype": "freemium",
    "price": 0,
    "extra": [{
        "icon": "",
        "description": "Ofertas en miles de comercios participantes, con hasta un 40% de descuento.",
        "title": "Club de descuentos"
    }, {
        "icon": "",
        "description": "Consulta en línea y receta digital válida para comprar tus medicamentos.",
        "title": "Telemedicina general ilimitada"
    }],
    "category": "celular",
    "type": "free",
    "plan_id": "P01"
}]

const arrayaPremium = [{
    "benefits": [{
        "icon": "",
        "description": "¿Se te rompió la pantalla? Te cubrimos con hasta $4,000 MXN para que la repares (1 evento al año con copago de $400 MXN).",
        "benefit_id": "C02",
        "title": "Reparación de pantalla"
    }, {
        "icon": "",
        "description": "Te pagamos hasta $10,000 MXN en caso de que seas víctima de robo con violencia (1 evento al año con un deducible del 20%).",
        "benefit_id": "C02",
        "title": "Robo de celular"
    }, {
        "icon": "",
        "description": "Protege a tus seres queridos con $20,000 MXN en caso de fallecimiento.",
        "benefit_id": "C02",
        "title": "Muerte accidental"
    }],
    "subtype": "premium",
    "price": 99,
    "extra": [{
        "icon": "",
        "description": "Consulta en línea y receta digital válida para comprar tus medicamentos.",
        "title": "Telemedicina general ilimitada"
    }, {
        "icon": "",
        "description": "Ofertas en miles de comercios participantes, con hasta un 40% de descuento.",
        "title": "Club de descuentos"
    }, {
        "icon": "",
        "description": "Podrás contratar tu seguro de auto, gastos médicos mayores o mascota a precio preferencial.",
        "title": "Descuentos Inter.mx"
    }],
    "category": "celular",
    "type": "premium",
    "plan_id": "P01"
}]

export enum MembershipCategory {
    salud = 'salud',
    celular = 'celular',
    //hogar = 'hogar'
}
export type MembershipCategoryType = keyof typeof MembershipCategory;

interface BenefitsInfo  {
    icon: string,
    title: string
    description: string,
    "benefit_id":string,

}
export interface MembershipPlansInfo {
    type:string,
    subtype:string,
    plan_id:string,
    category: string;
    price:number,
    extra:Array<IMemberShipExtra>
    benefits:Array<BenefitsInfo>,
}


export enum MembershipTypes {
    free = 'free',
    premium = 'premium'
}
export enum MembershipKeyTypes {
    freemium = 'freemium',
    premium = 'premium',

}
 export type MembershipKey = keyof typeof MembershipKeyTypes;
export type MembershipType = keyof typeof MembershipTypes;


export const MembershipPlans: { [key in MembershipKey]: MembershipPlansInfo } = {
    freemium: {
        type: 'free',
        subtype: 'freemium',
        price: 0,
        category: "ingresos",
        "plan_id": "RP01",
        benefits: [
            {
                "icon": "",
                "description": "Si por más de 7 días quedas incapacitado por enfermedad o accidente te regalamos $1,500 MXN",
                "benefit_id": "RC01",
                "title": "Continuidad de ingreso"
            }
        ],
        extra: [
            {
                "icon": "",
                "description": "Solo aplica para repartidores de Nivel Silver y Diamond.",
                "title": "Condiciones"
            }
        ],
    },
    premium: {
        subtype: 'premium',
        type: 'premium',
        price: 99,
        category: "ingresos",
        plan_id: "RP01",
        benefits: [
            {
                "icon": "",
                "description": "Si por más de 7 días quedas incapacitado por enfermedad o accidente te damos $7,000 MXN",
                "benefit_id": "RC02",
                "title": "Continuidad de ingreso"
            }
        ],
        extra: [
            {
                "icon": "",
                "description": "El reembolso sólo aplica para casos de enfermedad o accidente que te dejen sin laborar por más de 7 días",
                "title": "Condiciones"
            }
        ],
    }
}

export const ArrayFAQsHome: any = [

        {id:1,title:"¿Qué es Rappi Protect?",description:"Rappi protect es una iniciativa que tiene como objetivo principal proteger a todos los Rappitenderos sin costo, además de acercarlos al mundo de los seguros, dándoles la opción de una mejor protección."},
        {id:2,title:'¿Por que es de regalo?',description: 'Esta vez decidimos invertir en lo más importante, que eres tú, con el objetivo de brindarte la tranquilidad y seguridad que necesitas, así como seguir fomentando el crecimiento de tu carrera con rappi.'},
        {id:3,title:'¿Cuáles son los requisitos para obtener Rappi Protect de regalo?',description: 'Para poder disfrutar de rappi protect de regalo debes ser Rappitendero nivel silver'},
        {id:4,title:'¿Qué incluye la versión premium?',description: 'Con la versión premium, te apoyamos con $5,000 pesos en caso de que sufras un accidente o enfermedad que te imposibilite trabajar por más de 7 días.'},
        {id:5,title:'¿Cuáles son los requisitos para obtener Rappi Protect premium?',description: 'Para poder disfrutar de este beneficio unicamente debes ser rappitendero'},
    ]

export const Array_FAQs:any = [
    [
        {id:1,title:"¿Qué es Rappi Protect?",description:"Rappi protect es una iniciativa que tiene como objetivo principal proteger a todos los Rappitenderos sin costo, además de acercarlos al mundo de los seguros, dándoles la opción de una mejor protección."},
        {id:2,title:'¿Por que es de regalo?',description: 'Esta vez decidimos invertir en lo más importante, que eres tú, con el objetivo de brindarte la tranquilidad y seguridad que necesitas, así como seguir fomentando el crecimiento de tu carrera con rappi.'},
        {id:3,title:'¿Qué incluye la versión de regalo?',description: 'Con la versión de regalo, te apoyamos con $1,500 pesos en caso de que sufras un accidente o enfermedad que te imposibilite trabajar por más de 7 días'},
        {id:4,title:'¿Qué incluye la versión premium?',description: 'Con la versión premium, te apoyamos con $5,000 pesos en caso de que sufras un accidente o enfermedad que te imposibilite trabajar por más de 7 días.'},
        {id:5,title:'¿Cuáles son los requisitos para obtener Rappi Protect de regalo?',description: 'Para poder disfrutar de rappi protect de regalo debes ser Rappitendero nivel silver'},
        {id:6,title:'¿Cuáles son los requisitos para obtener Rappi Protect premium?',description: 'Para poder disfrutar de este beneficio unicamente debes ser rappitendero'},
        {id:7,title:'¿Cómo puedo acceder a la protección de Rappi Protect?',description: 'Es muy sencillo, solo necesitas darte de alta dentro de tu aplicación Rappi con tu número de colaborador para cualquiera de los dos planes.'},
        {id:8,title:'¿Cómo solicitar mi apoyo en caso de sufrir un accidente o enfermedad que me incapacite?',description: 'Una vez que te registres, haremos llegar a tu correo electrónico un kit de bienvenida donde vienen todas las instrucciones necesarias para disfrutar de tus beneficios.'},
        {id:9,title:'¿Cuál es la vigencia del plan?',description: 'La vigencia es ilimitada mientras decidas continuar con la protección, y los eventos se renuevan cada año, lo que quiere decir que puedes gozar de un apoyo por incapacidad al año.'},
        {id:10,title:'¿Cómo puedo pagar  Rappi Protect Premium?',description: 'Con tu tarjeta de crédito o débito, se te realizará el cargo mensualmente y puedes cancelarlo en cualquier momento.'}
    ],
    [
        {id:11,title:'¿Qué pasa una vez que utilice mi cobertura?',description: 'El programa sigue y podrás hacer uso nuevamente de tu cobertura una vez transcurridos 12 meses después de que te inscribiste.'},
        {id:12,title:'¿Qué pasa si concluye el año y no utilice mi cobertura?',description: 'Los eventos no son acumulables; puedes renovar tu plan para disfrutar de otro año de cobertura.'},
        {id:13,title:'¿Qué pasa si quiero cancelar mi plan?',description: 'Dentro del kit de bienvenida, podrás encontrar el proceso de cancelación o también nos puedes llamar al número 55 55 55 55 55.'},
        {id:14,title:'¿Cómo puedo renovar mi plan premium?',description: 'Tu plan se renueva de forma automática y puedes cancelarlo en cualquier momento.'},
        {id:15,title:'¿Qué pasa si dejo de pagar mi plan premium?',description: 'Si por algún motivo te resulta difícil realizar los pagos de tu plan, tendrás un mes para ponerte al corriente. Después de este período, tu plan se cancelará automáticamente. Recuerda que una vez que se haya cancelado tu plan, no será posible reactivarlo.'},
        {id:16,title:'¿Quién respalda este programa?',description: 'Para llevar a cabo esta iniciativa, contamos con el respaldo de Afirme Seguros'},
        {id:17,title:'¿Por qué si soy bronce no puedo tener Rappi protect?',description: 'Con esta iniciativa, buscamos premiar tu fidelidad. Para nosotros, es muy importante recompensarte por el tiempo que inviertes con nosotros. Es por esto que, una vez que te conviertas en Rappitendero Silver, tendrás acceso a este beneficio.'}
    ]
]

