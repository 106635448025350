import React from 'react';
import {styled} from "styled-components";

import {useMediaQuery, useTheme, Tabs, Tab} from "@mui/material";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


import AccordeonCustomized from '../../organisms/Acordeon';
import {Array_FAQs} from '../../../utils/Constants'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 80px;
  gap: 40px;
  align-items: flex-start;
  max-width: 946px;
`;

const SPANTitleBolded = styled.span`
  color: var(--Rappi-neon, #FE3F23);
  text-align: center;
  text-transform: uppercase;
  font-family: Jornada Sans;
  font-size: 41px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.41px;
`;

const SPANTitleTransparent = styled(SPANTitleBolded)`
  color: var(--Rappi-neon, #FE3F23);
  text-transform: uppercase;
  text-align: center;
  font-family: Jornada Sans;
  font-size: 41px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.41px;
`;

const InterFAQs: React.FC = () => {
    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState([]);
    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    React.useEffect(() => {
        setPage(0)
        setData(Array_FAQs[0])

    }, [])

    React.useEffect(() => {
        setData(Array_FAQs[page])
    }, [page]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value == 0 ? 1 :  value -1 );
    };

    return (
        <React.Fragment>

            <FormWrapper>
                <label style={{
                    width:'100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <SPANTitleTransparent>
                        <b>preguntas</b>
                    </SPANTitleTransparent>
                    <SPANTitleBolded>
                        <b>frecuentes</b>
                    </SPANTitleBolded>
                </label>

                <div style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: 12,
                    width: isMobile ? '' : '100%',
                    justifyContent: isMobile ? 'flex-start' : 'center'
                }}>
                </div>

                <AccordeonCustomized data={data}/>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Stack spacing={2}>
                        <Pagination color="primary" count={Array_FAQs.length} page={page + 1} onChange={handleChange}/>
                    </Stack>
                </div>
            </FormWrapper>
        </React.Fragment>
    );
};

export default InterFAQs;
