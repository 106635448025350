import React from "react";
import ErrorTemplate from "components/templates/ErrorTemplate"

const ErrorPage:React.FC = () => {

    return (
        <ErrorTemplate />
    )
}

export default ErrorPage;
