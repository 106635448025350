import React from "react";

const IconMiniLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="34" viewBox="0 0 32 34" fill="none">
            <path d="M0.528545 23.5459C0.468231 23.4916 0.42043 23.4247 0.388441 23.3501C0.356451 23.2755 0.341033 23.1948 0.34325 23.1136C0.340208 23.0315 0.355225 22.9497 0.387241 22.874C0.419258 22.7983 0.467495 22.7306 0.528545 22.6756C0.656591 22.5612 0.822913 22.4991 0.994584 22.5016C1.16405 22.4962 1.32902 22.5565 1.45501 22.67C1.51491 22.7226 1.56291 22.7874 1.59583 22.86C1.62874 22.9326 1.64582 23.0114 1.64592 23.0911C1.64734 23.1747 1.63163 23.2576 1.59976 23.3349C1.5679 23.4121 1.52054 23.4821 1.46063 23.5403C1.39954 23.6008 1.3266 23.648 1.2464 23.6789C1.16619 23.7098 1.08046 23.7238 0.994584 23.72C0.822425 23.7253 0.655068 23.6628 0.528545 23.5459ZM0.483624 24.4387H1.49993V28.7735H0.483624V24.4387Z" fill="#039ECC"/>
            <path d="M0.684846 32.3778C0.78266 32.4805 0.900251 32.5622 1.03053 32.6182C1.16081 32.6742 1.30107 32.7032 1.44287 32.7035C1.79099 32.7035 2.00436 32.5519 2.00436 32.3273C2.00436 32.1027 1.72361 31.9904 1.44287 31.9174C1.16212 31.8444 0.611848 31.7209 0.611848 31.2492C0.611848 30.8898 0.920674 30.5866 1.46533 30.5866C1.6226 30.5812 1.77935 30.6078 1.92605 30.6647C2.07276 30.7217 2.20638 30.8078 2.3188 30.9179L2.15035 31.165C2.06317 31.0712 1.95695 30.9971 1.8388 30.9477C1.72066 30.8983 1.59332 30.8748 1.46533 30.8786C1.1565 30.8786 0.959978 31.019 0.959978 31.2211C0.959978 31.4233 1.21827 31.5187 1.52147 31.5917C1.92014 31.6816 2.38056 31.7939 2.38056 32.3048C2.38056 32.6866 2.07174 32.9955 1.47655 32.9955C1.30551 33.0044 1.13451 32.977 0.974826 32.915C0.815139 32.8531 0.67038 32.758 0.550083 32.6361L0.684846 32.3778Z" fill="#039ECC"/>
            <path d="M3.9091 30.5872C4.066 30.5865 4.2213 30.6186 4.365 30.6816C4.5087 30.7446 4.63761 30.837 4.74341 30.9529C4.84921 31.0687 4.92957 31.2055 4.97929 31.3543C5.02901 31.5031 5.047 31.6607 5.03209 31.8168V31.9067H3.15108C3.15327 32.0136 3.1765 32.1191 3.21945 32.217C3.26241 32.315 3.32423 32.4035 3.4014 32.4775C3.47857 32.5516 3.56958 32.6097 3.66921 32.6485C3.76885 32.6874 3.87517 32.7063 3.98209 32.704C4.24708 32.7032 4.50115 32.5983 4.68958 32.412L4.85803 32.6423C4.73878 32.7604 4.59648 32.8528 4.44 32.9137C4.28351 32.9746 4.11618 33.0026 3.9484 32.996C3.64244 32.9807 3.35374 32.8497 3.14079 32.6295C2.92784 32.4092 2.80658 32.1163 2.80159 31.81C2.79659 31.5037 2.90822 31.2069 3.11387 30.9799C3.31952 30.7528 3.6038 30.6124 3.9091 30.5872ZM3.15108 31.6484H4.6615C4.66376 31.5477 4.64578 31.4476 4.60863 31.354C4.57148 31.2604 4.51591 31.1752 4.44522 31.1034C4.37453 31.0317 4.29015 30.9749 4.1971 30.9363C4.10404 30.8978 4.0042 30.8784 3.90348 30.8792C3.70286 30.885 3.51232 30.9684 3.37198 31.1119C3.23164 31.2554 3.15245 31.4477 3.15108 31.6484Z" fill="#039ECC"/>
            <path d="M5.76174 33.2655C5.85287 33.3724 5.96809 33.4562 6.09793 33.5099C6.22776 33.5636 6.36848 33.5857 6.50852 33.5743C6.60357 33.5859 6.70001 33.5766 6.79107 33.547C6.88213 33.5174 6.96561 33.4682 7.03568 33.403C7.10576 33.3377 7.16071 33.2579 7.19671 33.1692C7.23271 33.0805 7.24888 32.9849 7.24409 32.8893V32.558C7.15829 32.6832 7.04356 32.7858 6.90964 32.8571C6.77572 32.9285 6.62657 32.9665 6.47483 32.9679C5.87403 32.9679 5.45291 32.5187 5.45291 31.7775C5.43308 31.6307 5.44455 31.4813 5.48658 31.3392C5.52861 31.1971 5.60025 31.0655 5.69677 30.953C5.7933 30.8406 5.91253 30.7499 6.04664 30.6868C6.18074 30.6237 6.32667 30.5898 6.47483 30.5872C6.62599 30.5884 6.77465 30.6258 6.90846 30.6961C7.04226 30.7664 7.15733 30.8677 7.24409 30.9914V30.6433H7.59783V32.8893C7.59783 33.6529 7.03633 33.8888 6.47483 33.8888C6.30483 33.9021 6.13395 33.8783 5.97401 33.8192C5.81406 33.7601 5.66884 33.6669 5.54836 33.5462L5.76174 33.2655ZM7.24409 31.2778C7.16851 31.1651 7.06667 31.0725 6.94733 31.0079C6.82799 30.9434 6.69473 30.9088 6.55906 30.9072C6.09863 30.9072 5.8235 31.2722 5.8235 31.7775C5.8235 32.2829 6.09863 32.6535 6.55906 32.6535C6.69514 32.6508 6.82855 32.6151 6.94785 32.5496C7.06715 32.4841 7.1688 32.3907 7.24409 32.2773V31.2778Z" fill="#039ECC"/>
            <path d="M9.84237 32.6139C9.73965 32.7309 9.61373 32.8253 9.47261 32.891C9.33148 32.9567 9.17823 32.9924 9.02258 32.9957C8.92345 33.0098 8.8224 33.0006 8.72743 32.9689C8.63246 32.9372 8.54617 32.8838 8.47537 32.813C8.40457 32.7422 8.35121 32.6559 8.31949 32.5609C8.28778 32.466 8.27858 32.3649 8.29263 32.2658V30.6431H8.64638V32.1535C8.64638 32.5578 8.85413 32.6757 9.16295 32.6757C9.29467 32.6734 9.42416 32.6414 9.54179 32.5821C9.65942 32.5228 9.76216 32.4377 9.84237 32.3332V30.6487H10.1961V32.9452H9.84237V32.6139Z" fill="#039ECC"/>
            <path d="M10.9326 30.6428H11.292V31.0134C11.3782 30.8896 11.4921 30.7876 11.6247 30.7155C11.7572 30.6433 11.9048 30.6031 12.0556 30.5979V30.9629C12.0071 30.9565 11.9581 30.9565 11.9096 30.9629C11.7859 30.9737 11.6662 31.0121 11.5594 31.0753C11.4525 31.1384 11.3611 31.2247 11.292 31.3278V32.9562H10.9326V30.6428Z" fill="#039ECC"/>
            <path d="M12.3712 31.789C12.3876 31.5712 12.4671 31.363 12.6 31.1897C12.7329 31.0164 12.9135 30.8857 13.1196 30.8134C13.3257 30.7412 13.5484 30.7306 13.7604 30.783C13.9724 30.8354 14.1645 30.9485 14.3132 31.1084C14.462 31.2683 14.5609 31.4681 14.5978 31.6833C14.6348 31.8985 14.6081 32.1199 14.5212 32.3202C14.4343 32.5205 14.2908 32.6912 14.1084 32.8113C13.926 32.9313 13.7126 32.9956 13.4942 32.9962C13.3393 32.9967 13.1861 32.9651 13.044 32.9034C12.902 32.8418 12.7742 32.7515 12.6688 32.6381C12.5633 32.5247 12.4824 32.3908 12.4312 32.2447C12.38 32.0985 12.3596 31.9434 12.3712 31.789ZM14.269 31.789C14.2475 31.642 14.1841 31.5043 14.0865 31.3922C13.9889 31.2802 13.8611 31.1986 13.7184 31.1572C13.5758 31.1158 13.4242 31.1162 13.2817 31.1585C13.1393 31.2008 13.012 31.2832 12.9151 31.3958C12.8181 31.5084 12.7556 31.6465 12.735 31.7937C12.7144 31.9408 12.7365 32.0908 12.7987 32.2257C12.8609 32.3607 12.9606 32.4749 13.0859 32.5547C13.2112 32.6346 13.3568 32.6768 13.5054 32.6762C13.6153 32.6722 13.7231 32.6456 13.8222 32.598C13.9213 32.5504 14.0094 32.4828 14.0811 32.3995C14.1528 32.3162 14.2066 32.219 14.2389 32.1139C14.2712 32.0088 14.2815 31.8982 14.269 31.789Z" fill="#039ECC"/>
            <path d="M15.0985 33.3944C15.2574 33.2934 15.3703 33.1341 15.413 32.9508H15.3456C15.3142 32.9517 15.2829 32.9459 15.2538 32.9339C15.2247 32.922 15.1985 32.904 15.1768 32.8813C15.1551 32.8585 15.1384 32.8314 15.1278 32.8018C15.1173 32.7722 15.113 32.7407 15.1154 32.7093C15.1146 32.6757 15.1206 32.6422 15.133 32.6109C15.1454 32.5796 15.1638 32.5511 15.1874 32.527C15.2109 32.503 15.239 32.4839 15.2701 32.4708C15.3011 32.4578 15.3344 32.451 15.3681 32.451C15.4119 32.4545 15.4547 32.4668 15.4937 32.4872C15.5326 32.5077 15.5671 32.5358 15.5949 32.5699C15.6227 32.604 15.6433 32.6434 15.6555 32.6857C15.6676 32.728 15.6711 32.7723 15.6657 32.816C15.6611 32.9569 15.6237 33.0947 15.5565 33.2185C15.4892 33.3423 15.3939 33.4487 15.2782 33.5291L15.0985 33.3944Z" fill="#039ECC"/>
            <path d="M17.4911 32.3777C17.589 32.4804 17.7066 32.5621 17.8368 32.6181C17.9671 32.6741 18.1074 32.7031 18.2492 32.7034C18.6029 32.7034 18.8107 32.5518 18.8107 32.3272C18.8107 32.1026 18.5355 31.9903 18.2211 31.9173C17.9067 31.8443 17.3901 31.7208 17.3901 31.2491C17.3901 30.8897 17.6989 30.5865 18.2492 30.5865C18.4056 30.5815 18.5613 30.6083 18.7071 30.6653C18.8528 30.7222 18.9855 30.8081 19.097 30.9178L18.9342 31.1649C18.8464 31.0719 18.74 30.9984 18.622 30.9491C18.504 30.8998 18.377 30.8757 18.2492 30.8785C17.9347 30.8785 17.7382 31.0189 17.7382 31.221C17.7382 31.4232 18.0021 31.5186 18.2997 31.5916C18.6984 31.6815 19.1588 31.7938 19.1588 32.3047C19.1588 32.6865 18.85 32.9954 18.2548 32.9954C18.0839 33.0032 17.9132 32.9753 17.7536 32.9134C17.5941 32.8516 17.4492 32.7571 17.3283 32.636L17.4911 32.3777Z" fill="#039ECC"/>
            <path d="M20.7137 30.5872C20.8706 30.5865 21.0259 30.6186 21.1696 30.6816C21.3133 30.7446 21.4422 30.837 21.548 30.9529C21.6538 31.0687 21.7341 31.2055 21.7839 31.3543C21.8336 31.5031 21.8516 31.6607 21.8367 31.8168V31.9067H19.95C19.9515 32.0134 19.974 32.1187 20.0164 32.2166C20.0587 32.3145 20.12 32.4031 20.1967 32.4772C20.2735 32.5513 20.3641 32.6095 20.4634 32.6484C20.5627 32.6874 20.6688 32.7062 20.7754 32.704C20.9068 32.7042 21.0368 32.6785 21.1582 32.6284C21.2796 32.5783 21.39 32.5048 21.4829 32.412L21.657 32.6423C21.5363 32.7601 21.3929 32.8522 21.2356 32.913C21.0783 32.9738 20.9103 33.002 20.7417 32.996C20.4358 32.9807 20.1471 32.8497 19.9341 32.6294C19.7212 32.4092 19.5999 32.1163 19.5949 31.81C19.5899 31.5037 19.7016 31.2069 19.9072 30.9799C20.1129 30.7528 20.3971 30.6124 20.7024 30.5872H20.7137ZM19.95 31.6484H21.4548C21.4579 31.5475 21.4404 31.447 21.4036 31.3531C21.3667 31.2591 21.3112 31.1736 21.2403 31.1017C21.1695 31.0298 21.0848 30.973 20.9914 30.9347C20.8979 30.8965 20.7977 30.8776 20.6968 30.8792C20.4962 30.8836 20.3055 30.9667 20.1657 31.1107C20.026 31.2546 19.9485 31.4478 19.95 31.6484Z" fill="#039ECC"/>
            <path d="M22.5679 33.2655C22.659 33.3724 22.7742 33.4562 22.9041 33.5099C23.0339 33.5636 23.1746 33.5857 23.3147 33.5743C23.4097 33.5859 23.5062 33.5766 23.5972 33.547C23.6883 33.5174 23.7718 33.4682 23.8418 33.403C23.9119 33.3377 23.9669 33.2579 24.0029 33.1692C24.0389 33.0805 24.055 32.9849 24.0502 32.8893V32.558C23.9644 32.6832 23.8497 32.7858 23.7158 32.8571C23.5819 32.9285 23.4327 32.9665 23.281 32.9679C22.9934 32.9283 22.7299 32.7859 22.5391 32.5671C22.3484 32.3483 22.2433 32.0678 22.2433 31.7775C22.2433 31.4872 22.3484 31.2068 22.5391 30.9879C22.7299 30.7691 22.9934 30.6268 23.281 30.5872C23.4321 30.5884 23.5808 30.6258 23.7146 30.6961C23.8484 30.7664 23.9635 30.8677 24.0502 30.9914V30.6433H24.4096V32.8893C24.4096 33.6529 23.8481 33.8888 23.2866 33.8888C23.1166 33.9021 22.9457 33.8783 22.7858 33.8192C22.6258 33.7601 22.4806 33.6669 22.3601 33.5462L22.5679 33.2655ZM24.0502 31.2778C23.9759 31.1652 23.8751 31.0726 23.7567 31.0079C23.6382 30.9433 23.5057 30.9088 23.3708 30.9072C22.9048 30.9072 22.6296 31.2722 22.6296 31.7775C22.6296 32.2829 22.9048 32.6535 23.3708 32.6535C23.5061 32.6508 23.6388 32.6152 23.7572 32.5496C23.8756 32.4841 23.9762 32.3906 24.0502 32.2773V31.2778Z" fill="#039ECC"/>
            <path d="M26.6875 32.6139C26.5848 32.7309 26.4589 32.8253 26.3177 32.891C26.1766 32.9567 26.0234 32.9924 25.8677 32.9957C25.7686 33.0098 25.6675 33.0006 25.5726 32.9689C25.4776 32.9372 25.3913 32.8838 25.3205 32.813C25.2497 32.7422 25.1963 32.6559 25.1646 32.5609C25.1329 32.466 25.1237 32.3649 25.1378 32.2658V30.6431H25.4915V32.1535C25.4915 32.5578 25.6993 32.6757 26.0081 32.6757C26.1398 32.6734 26.2693 32.6414 26.3869 32.5821C26.5046 32.5228 26.6073 32.4377 26.6875 32.3332V30.6487H27.0413V32.9452H26.6875V32.6139Z" fill="#039ECC"/>
            <path d="M27.7389 30.6428H28.0982V31.0134C28.1845 30.8896 28.2984 30.7876 28.431 30.7155C28.5635 30.6433 28.7111 30.6031 28.8619 30.5979V30.9629C28.8153 30.9563 28.7681 30.9563 28.7215 30.9629C28.5968 30.9729 28.4761 31.0109 28.3682 31.0741C28.2602 31.1373 28.168 31.224 28.0982 31.3278V32.9562H27.7389V30.6428Z" fill="#039ECC"/>
            <path d="M29.1753 31.789C29.1917 31.5712 29.2712 31.363 29.4041 31.1897C29.5371 31.0164 29.7176 30.8857 29.9237 30.8134C30.1298 30.7412 30.3525 30.7306 30.5645 30.783C30.7765 30.8354 30.9686 30.9485 31.1174 31.1084C31.2661 31.2683 31.365 31.4681 31.402 31.6833C31.4389 31.8985 31.4123 32.1199 31.3253 32.3202C31.2384 32.5205 31.095 32.6912 30.9126 32.8113C30.7301 32.9313 30.5167 32.9956 30.2983 32.9962C30.1435 32.9967 29.9902 32.9651 29.8482 32.9034C29.7061 32.8418 29.5784 32.7515 29.4729 32.6381C29.3674 32.5247 29.2866 32.3908 29.2353 32.2447C29.1841 32.0985 29.1637 31.9434 29.1753 31.789ZM31.0732 31.789C31.0516 31.642 30.9883 31.5043 30.8907 31.3922C30.793 31.2802 30.6653 31.1986 30.5226 31.1572C30.3799 31.1158 30.2283 31.1162 30.0859 31.1585C29.9434 31.2008 29.8161 31.2832 29.7192 31.3958C29.6223 31.5084 29.5598 31.6465 29.5391 31.7937C29.5185 31.9408 29.5406 32.0908 29.6028 32.2257C29.665 32.3607 29.7647 32.4749 29.89 32.5547C30.0153 32.6346 30.1609 32.6768 30.3095 32.6762C30.4194 32.6722 30.5272 32.6456 30.6263 32.598C30.7254 32.5504 30.8136 32.4828 30.8853 32.3995C30.957 32.3162 31.0107 32.219 31.043 32.1139C31.0754 32.0088 31.0856 31.8982 31.0732 31.789Z" fill="#039ECC"/>
            <path d="M6.19824 24.8762C6.37334 25.0666 6.50752 25.2909 6.59251 25.5353C6.6775 25.7796 6.71148 26.0388 6.69236 26.2968V28.7842H5.68166V26.4315C5.70538 26.1212 5.60889 25.8136 5.41214 25.5724C5.31235 25.4734 5.19269 25.3967 5.06105 25.3473C4.92942 25.2979 4.78881 25.2771 4.64851 25.2861C4.48536 25.2777 4.32226 25.3033 4.16954 25.3613C4.01682 25.4193 3.87781 25.5084 3.76134 25.623C3.52967 25.8916 3.41272 26.2404 3.43567 26.5943V28.7842H2.41936V24.4494H3.39076V25.0109C3.56051 24.8107 3.7763 24.6546 4.01963 24.5561C4.29576 24.4517 4.58913 24.4002 4.88434 24.4045C5.12367 24.3921 5.36308 24.4275 5.58864 24.5084C5.81421 24.5894 6.02142 24.7144 6.19824 24.8762Z" fill="#039ECC"/>
            <path d="M10.1856 28.5488C10.0599 28.6535 9.91205 28.7284 9.75325 28.7678C9.57089 28.8206 9.38154 28.8452 9.19175 28.8408C8.98938 28.8562 8.78597 28.8313 8.59323 28.7677C8.4005 28.7041 8.22225 28.603 8.06876 28.4702C7.93286 28.325 7.82832 28.1533 7.76167 27.9659C7.69501 27.7785 7.66766 27.5794 7.68133 27.3809V25.2922H7.00192V24.478H7.72063V23.4897H8.7201V24.478H9.8824V25.2922H8.7201V27.3585C8.71039 27.5334 8.76647 27.7057 8.87732 27.8413C8.93498 27.8991 9.00454 27.9436 9.08114 27.9717C9.15775 27.9998 9.23957 28.0109 9.3209 28.0042C9.5232 28.011 9.72161 27.9475 9.8824 27.8245L10.1856 28.5488Z" fill="#039ECC"/>
            <path d="M14.532 26.9429H11.1293C11.1792 27.247 11.3465 27.5193 11.5954 27.701C11.8694 27.8934 12.1984 27.9919 12.5331 27.9817C12.7515 27.9887 12.9691 27.9505 13.172 27.8693C13.3749 27.7881 13.5589 27.6658 13.7122 27.5101L14.2737 28.1333C14.0755 28.3694 13.8204 28.551 13.5325 28.6611C13.2128 28.7872 12.8711 28.8482 12.5275 28.8408C12.0917 28.8496 11.6603 28.7533 11.2697 28.5601C10.9188 28.3824 10.6264 28.1076 10.4275 27.7683C10.2267 27.4184 10.1239 27.0207 10.1299 26.6173C10.1272 26.2238 10.2278 25.8366 10.4218 25.4943C10.6049 25.1584 10.882 24.8833 11.2192 24.7026C11.5733 24.5082 11.972 24.4095 12.3759 24.4162C12.7683 24.4101 13.1555 24.5069 13.4989 24.697C13.8296 24.878 14.1007 25.151 14.2793 25.4831C14.4738 25.843 14.5706 26.2476 14.5601 26.6566C14.5526 26.7202 14.5432 26.8157 14.532 26.9429ZM11.528 25.4999C11.2995 25.6956 11.1531 25.9703 11.1181 26.2692H13.5775C13.5451 25.9736 13.4028 25.701 13.1788 25.5055C12.9505 25.3074 12.6555 25.203 12.3534 25.2135C12.0525 25.2047 11.7588 25.3066 11.528 25.4999Z" fill="#039ECC"/>
            <path d="M17.6257 24.3989V25.3647C17.5473 25.3569 17.4683 25.3569 17.3899 25.3647C17.221 25.3544 17.0518 25.3796 16.8932 25.4385C16.7346 25.4975 16.5901 25.589 16.469 25.7072C16.3501 25.8424 16.259 25.9999 16.2012 26.1704C16.1434 26.341 16.1199 26.5214 16.1321 26.7011V28.8067H15.1158V24.4719H16.0872V25.1008C16.259 24.8586 16.4918 24.6661 16.762 24.5428C17.0322 24.4196 17.3302 24.3699 17.6257 24.3989Z" fill="#039ECC"/>
            <path d="M17.8942 28.6497C17.8297 28.5873 17.7789 28.5121 17.7451 28.4289C17.7112 28.3458 17.6951 28.2565 17.6977 28.1668C17.6959 28.0768 17.7118 27.9874 17.7445 27.9035C17.7773 27.8197 17.8263 27.7432 17.8886 27.6783C18.019 27.5591 18.1892 27.493 18.3659 27.493C18.5425 27.493 18.7128 27.5591 18.8431 27.6783C18.9055 27.7432 18.9544 27.8197 18.9872 27.9035C19.02 27.9874 19.0359 28.0768 19.0341 28.1668C19.0304 28.2952 18.9901 28.4199 18.918 28.5262C18.8458 28.6325 18.7447 28.7159 18.6267 28.7667C18.5087 28.8174 18.3786 28.8334 18.2518 28.8127C18.125 28.7919 18.0068 28.7354 17.9111 28.6497H17.8942Z" fill="#039ECC"/>
            <path d="M26.3914 24.8759C26.5634 25.068 26.6949 25.2929 26.7778 25.537C26.8608 25.7811 26.8936 26.0394 26.8743 26.2965V28.784H25.858V26.4313C25.8815 26.1237 25.7916 25.8181 25.6054 25.5722C25.5131 25.4741 25.4004 25.3976 25.2752 25.3481C25.15 25.2986 25.0154 25.2774 24.881 25.2858C24.7282 25.2796 24.5758 25.3065 24.4343 25.3646C24.2928 25.4228 24.1655 25.5108 24.0612 25.6227C23.8394 25.8916 23.7289 26.2352 23.7524 26.5829V28.784H22.7586V26.4313C22.7821 26.1237 22.6922 25.8181 22.5059 25.5722C22.4152 25.4736 22.3036 25.3967 22.1792 25.3471C22.0547 25.2976 21.9208 25.2766 21.7872 25.2858C21.6337 25.2773 21.4803 25.3025 21.3377 25.3598C21.195 25.417 21.0667 25.5049 20.9618 25.6171C20.7431 25.8896 20.6349 26.2343 20.6586 26.5829V28.784H19.6423V24.4492H20.608V25.0107C20.7719 24.8152 20.9797 24.6613 21.2144 24.5615C21.4732 24.458 21.7499 24.4065 22.0286 24.4099C22.3273 24.4042 22.6232 24.4676 22.8933 24.5952C23.1451 24.7162 23.3531 24.9124 23.4885 25.1567C23.672 24.9255 23.9091 24.7424 24.1791 24.6233C24.4744 24.493 24.7942 24.4279 25.1168 24.4324C25.3481 24.4184 25.5798 24.4504 25.7986 24.5265C26.0174 24.6026 26.2189 24.7214 26.3914 24.8759Z" fill="#039ECC"/>
            <path d="M30.5229 28.784L29.3999 27.2848L28.2769 28.784H27.1539L28.8384 26.5829L27.2213 24.4492H28.3443L29.4111 25.8698L30.4724 24.4492H31.5954L29.9726 26.5661L31.6571 28.8121L30.5229 28.784Z" fill="#039ECC"/>
            <path d="M30.0015 9.45767L28.974 8.43012L20.9109 0.367026C20.8155 0.271809 20.6997 0.199568 20.5723 0.155788C20.4448 0.112007 20.3091 0.0978387 20.1753 0.114354C19.5631 0.149264 18.9682 0.330247 18.4403 0.642163C17.9686 0.995906 17.7384 1.76516 17.7384 3.02853V17.9643C17.7384 19.2558 17.9686 20.0643 18.4403 20.3732C18.9846 20.6485 19.5782 20.8126 20.1866 20.8561C20.3208 20.875 20.4576 20.8626 20.5862 20.8197C20.7148 20.7769 20.8317 20.7047 20.9277 20.609L30.0015 11.5408C30.2769 11.2641 30.4314 10.8896 30.4314 10.4992C30.4314 10.1089 30.2769 9.73439 30.0015 9.45767Z" fill="#039ECC"/>
            <path d="M10.5567 20.6087C10.6532 20.7038 10.7702 20.7754 10.8986 20.8182C11.0271 20.8611 11.1637 20.8739 11.2979 20.8558C11.9064 20.8133 12.5002 20.6491 13.0441 20.3729C13.5158 20.0641 13.7516 19.2499 13.7516 17.9641V3.02827C13.7516 1.79298 13.5158 0.995652 13.0441 0.641909C12.5187 0.329026 11.9253 0.147936 11.3147 0.1141C11.1802 0.097957 11.0437 0.112285 10.9154 0.156022C10.7871 0.19976 10.6703 0.271784 10.5736 0.366772L1.48292 9.45741C1.20758 9.73414 1.05302 10.1086 1.05302 10.499C1.05302 10.8894 1.20758 11.2638 1.48292 11.5406L10.5567 20.6087Z" fill="#039ECC"/>
        </svg>
    );
}

export default IconMiniLogo;
