import React, { useRef, useEffect, useState } from "react";
import CustomContainer from "components/organisms/Container/Container";
import { CustomTypography } from "components/atoms/Label/Label";
import { styled } from "styled-components";
import {useMediaQuery, useTheme, Button, Box} from "@mui/material";
import TotalCard, { TotalCardItem } from "components/molecules/TotalCard/TotalCard";
import { useNavigate, useLocation } from "react-router-dom";
import giftcard from "../../../../../assets/gifs/gifcard.gif";
import { useSelector } from "react-redux";
import { IStorageMembershipData } from 'utils/localStorageKeys';

import {
   useStripe,
   useElements,
   CardNumberElement,
   CardCvcElement,
   CardExpiryElement,
 } from "@stripe/react-stripe-js";

import { ArrowBack } from '@mui/icons-material';
import { CustomButton } from 'components/atoms/Buttons/Buttons';
import ModalPaymentmethod from "./ModalPaymentMethod";
import {InterInstance} from "api/config";
import PaymentFrequency from "components/organisms/PaymentFrequency";
import RenderAlert from "components/organisms/Modal";
import ModalAlertPayement from "components/organisms/Form/FormContent/MakePayment/ModalAlertPayement";

const items: TotalCardItem[] = [
  {
    label: "Membresia Protección de ingreso Premium",
    price: 99,
    sublabel: "al mes",
  },
];

const LayOutContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 85px;
    gap: 40px;
    align-items: center;
    text-align: center;
    max-width: 946px;
    text-wrap: balance;

    @media (max-width: 600px) {
      max-width: 100%;
      overflow-x: hidden;
      padding: 0 20px; // Ajusta el padding según tus necesidades
    }

    @media (min-width: 900px) {
      display: grid;
      grid-template-columns: 1fr 0.7fr;
      grid-template-rows: auto;
      grid-gap: 40px 24px;
      align-items: stretch;
      text-align: left;
      text-wrap: inherit;
    }
  `;

type MakePaymentTemplateProps = {
  onlyCardPresentation?: boolean;
  setActiveStep?: any;
  handleChange?: any;
}

const MakePaymentTemplate: React.FC<MakePaymentTemplateProps> = ({ onlyCardPresentation, setActiveStep, handleChange }) => {
  const [ openModal, setOpenModal ] = useState<boolean>(false);
    const [ errorMessage, setErrorMessage ] = useState<string>("");

    const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(false);
  const tmpOptions = {
    placeholder: "CVV",
    style: {
      base: {
        fontSize: "16px",
        fonstStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        padding: "20px 0px 0px 0px",
      },
      invalid: {
        color: "#9e2146",
      },
    }
  }
  const formRef = useRef<HTMLFormElement>(null);
  const location = useLocation();

    const userUpdate = useSelector((state: any) => state.trackingId.response) as
        | any
        | null;
  const [ success, setSuccess ] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  //const gadgets = useSelector((state: any) => state.gadgets.response)
  let storage: IStorageMembershipData = {
    ...useSelector((state: any) => state.currentValue.response),
  };

  const userResponse = useSelector((state: any) => state.formRegister.response);

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isDesktop = useMediaQuery(breakpoints.up('md'));

  let intermediateJson = giftcard;

  const tracking = useSelector((state: any) => state.tracking.response);
  const data = useSelector((state: any) => state.formRegister?.response);
  const currentValue = useSelector((state: any) => state.currentValue.response);
  const formRegister = useSelector((state: any) => state.formRegister.response);

  const navigateToPayment = (path: string, success: boolean) => {
    setLoading(false);
    navigate('/payment', { state: { path: path, success: success } })
  }


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    //navigate('/exito-registro', { replace: true })
     event.preventDefault();
     if (!stripe || !elements) {
       return;
     }

    let inputElement = document.querySelector('input[name="name_credit_card"]');
     let inputValue = (inputElement as HTMLInputElement).value;

     const cardElement = elements.getElement(CardNumberElement);

     if (!cardElement) {
      return RenderAlert({ severity: "error", message: "El número de tarjeta es necesario" })
     }
     const payload = await stripe.createPaymentMethod({
       type: "card",
       card: cardElement,
      billing_details: {
        name: inputValue,
       },
     });

    const userIsAlreadyRegistered = userUpdate.data.access_token
    if (userIsAlreadyRegistered) {
      //Flujo de freemium a premium
      const customerObject = {
        event: "stripe-customer",
        content: {
            business:currentValue.type,
            tracking_id: tracking?.data?.tracking_id,
            payment_method: {
            id: payload.paymentMethod?.id,
            card: payload.paymentMethod?.card,
          }
        }
      }
      try {
        await createCustomer(customerObject)
      } catch (error:any) {
          //alert("error")
        //console.error(error?.response?.data?.data.message)
        //Mandar a pantalla de error de pago
        if(onlyCardPresentation){
            if (error?.response?.data.code===400){
                setErrorMessage(error?.response?.data?.data.message)
            }
          setSuccess(false);
          setOpenModal(true);
        }
      }
    } else {
      //Flujo directo a premium
      const objectCustomerBefore = {
        event: "stripe-customer-before",
        content: {
          business:currentValue.type,
          tracking_id: tracking?.data?.tracking_id,
          name: formRegister.fullName,
          phone: formRegister.phone,
          email: formRegister.email,
          payment_method: {
            id: payload.paymentMethod?.id,
           card: payload.paymentMethod?.card,
          }
        }
      }
      try {
        await customerBefore(objectCustomerBefore)
      } catch (error) {
        console.error(error)
      }
    }
  };

  //Metodos a utilizar para usuario sin registro previo
  const customerBefore = async (customerBefore: any) => {
    try {
     const { data } = await InterInstance.post("payments/stripe", customerBefore)
      await customerAfter(data)
    } catch (error) {
      setSuccess(false);
      setLoading(false);
      setOpenModal(true);
      console.error(error)
    }
  }

  const customerAfter = async (customerAfter: any) => {
    try {
      const objectCustomerAfter = {
        event: "stripe-subscription-before",
        content: {
            business:currentValue.type,
            tracking_id: tracking?.data?.tracking_id,
            token: customerAfter?.data?.token,
            benefit_id: currentValue.memberShipProduct.benefits[0].benefit_id,
        },
      }
     const { data: tmpData } = await InterInstance.post("payments/stripe", objectCustomerAfter)
      if (tmpData) {

        //Mandar a pantalla de exito
        window.dataLayer.push({
          event: 'Rappi_pagar',
          boton_contacto: 'Pagar',
          total_pago: items[0]?.price,
          correo: userResponse?.email,
          tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
        });
        sessionStorage.setItem('exitoPremium', '1');

        if(onlyCardPresentation){
          setSuccess(true);
          setOpenModal(true);
          ///setLoading(false);
            return
        }else{
         // navigateToPayment("/crear-cuenta", true)
          //return
        }
      }
      //Mandar a pantalla de error de pago
      if(onlyCardPresentation){
        setSuccess(false);
        setOpenModal(true);
        setLoading(false);
      }else {
        //navigateToPayment("/contratacion", false)
      }
    } catch (error) {
      console.error(error)
        setSuccess(false);
        setOpenModal(true);
        setLoading(false);

    }
  }

  //Metodos a utilizar para usuario freemium
  const createCustomer = async (createCustomerObject: any) => {
    const { data } = await InterInstance.post("payments/stripe", createCustomerObject)

    if (data) {
      await createSubscription()
    }
  }

  useEffect(() => {
    // handleChange(data)
  }, []);

  const createSubscription = async () => {
    const customer = {
      event: "stripe-subscription",
      content: {
          business:currentValue.type,
          tracking_id: tracking?.data?.tracking_id,
          benefit_id: currentValue.memberShipProduct.benefits[0].benefit_id
      }
    }
    const { data } = await InterInstance.post("payments/stripe", customer)
    if (data) {
      //Mandar a pantalla de exito
      sessionStorage.setItem('exitoPremium', '1');
      if(onlyCardPresentation){
        window.dataLayer.push({
          event: 'Rappi_pagar',
          boton_contacto: 'Pagar',
          total_pago: items[0]?.price,
          correo: userResponse?.email,
          tipo_seguro: storage.subtype == "freemium" ? "De regalo" : "Premium",
        });
        setSuccess(true);
        setOpenModal(true);
        handleChange(data)
        return
      }else {
        //navigateToPayment("/portal-del-cliente", true)
        return
      }
    }
    //Mandar a pantalla de error de pago
    if(onlyCardPresentation){
        alert(1)
      setSuccess(false);
      setOpenModal(true);
    }else {
     // navigateToPayment("/contratacion", false)
    }
  }

  const userData = JSON.parse(sessionStorage.getItem('user-storage')!);



  useEffect(() => {
    if(sessionStorage?.exitoPremium === '1'){
      setSuccess(true)
      setOpenModal(true)
    }
  }, [sessionStorage?.exitoPremium]);

  let time:any = null

  useEffect(() => {
   if (success) {
       time = setTimeout(() => {
        navigate('/exito-registro', { replace: true })
        setOpenModal(false);
      }, 3000)
    }
    return()=>{
      clearTimeout(time);
    }
  }, [success]);

  return (
    <React.Fragment>
      <ModalAlertPayement open={openModal} setOpenModal={setOpenModal} onClick={() => {
        if(success){

        }else{
          setOpenModal(false);
          setErrorMessage("")
        }
      }} success={success} errorMessage={errorMessage} />
      <CustomContainer
        childComponent={
          <React.Fragment>
            <div style={onlyCardPresentation ? {} : { marginTop: "40px" }}>
              <div
                style={{
                  display: "flex",
                  flexFlow: isMobile ? "column" : "",
                  width: "100%",
                  justifyContent: 'center'
                }}
              >
              {
                onlyCardPresentation &&
                  <Box sx={{
                    width: '100%'
                  }}>
                    <LayOutContainer style={{display:'flex', marginBottom: 0, marginTop: 0}}>
                      <Box
                        sx={{
                          display: "flex",
                          flexFlow: "column",
                          alignItems: 'center',
                          padding: '20px 16px',
                          width: isMobile ? '100%' : '90%'
                        }}
                      >
                        <div style={{
                          display: "flex", flexDirection: "column",
                        }}>
                          <img
                            src={intermediateJson}
                            alt=""
                            style={{
                              maxWidth: "100%", width: '176px',
                              height: '176px'
                            }}
                          />


                        </div>

                        <Box sx={{
                          width: "100%",
                          margin: "0 auto",
                          marginBottom: isMobile ? "30px" : ""
                        }}>

                          <input
                            style={{
                              width: "100%",
                              display: "flex",
                              height: "28px",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: "8px",
                              alignSelf: "stretch",
                              outline: "none",
                              borderTop: "none",
                              borderRight: "none",
                              borderBottom: "1px solid black",
                              borderLeft: "none",
                              fontSize: 16,
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: 'normal'
                            }}
                            name="name_credit_card"
                            type="text"
                            placeholder="Nombre Del Titular"
                            required
                          />
                          <div style={{ marginTop: "40px" }}>
                            <CardNumberElement
                              options={{
                                ...tmpOptions,
                                placeholder: "Número de tarjeta"
                              }}
                              onReady={() => {

                              }}
                              onChange={(event) => {

                              }}
                              onBlur={() => {

                              }}
                              onFocus={() => {

                              }}
                            />
                            <div style={{ borderBottom: "1px solid black" }} />
                          </div>

                          <div style={{
                            width: "100%",
                            display: "flex",

                            justifyContent: "space-between",
                            marginTop: "40px"
                          }}>
                            <div style={{ width: "45%" }}>
                              <CardExpiryElement
                                options={{
                                  ...tmpOptions,
                                  placeholder: "Vigencia"
                                }}
                                onReady={() => {
                                }}
                                onChange={(event) => {
                                }}
                                onBlur={() => {
                                }}
                                onFocus={() => {
                                }}
                              />
                              <div style={{ borderBottom: "1px solid black" }} />
                            </div>

                            <div style={{ width: "45%" }}>
                              <CardCvcElement
                                options={{
                                  ...tmpOptions,
                                  placeholder: "CVV"
                                }}
                                onReady={() => {
                                }}
                                onChange={(event) => {
                                }}
                                onBlur={() => {
                                }}
                                onFocus={() => {
                                }}
                              />
                              <div style={{ borderBottom: "1px solid black" }} />
                            </div>

                          </div>
                          <PaymentFrequency />

                        </Box>

                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexFlow: "column",
                          width: isMobile ? "100%" : "90%",
                          background: "#FFF0DD",
                          gap: '36px',
                          padding: '16px 16px 0 16px',
                        }}
                      >
                          <TotalCard
                              style={{
                                root: {
                                  width: "100%",
                                  display: "flex",
                                  padding: '16px 0 16px 0',
                                  flexDirection: "column",
                                  gap: 24,
                                  alignSelf: "stretch",
                                  borderRadius: 8,
                                },
                                title: {
                                  margin: 0,
                                  color: "var(--Black, #212121)",
                                  fontSize: 18,
                                  fontWeight: 700,
                                  lineHeight: "normal",
                                  textAlign: "left",
                                },
                                itemsCard: {
                                  gap: 24,
                                },
                                totalInfo: {
                                  display:'flex',
                                  width:'100%',
                                  flexDirection: "column",
                                  margin: 0,
                                  textAlign: "left",
                                  color: "var(--Black, #212121)",
                                  fontSize: 18,
                                  fontWeight: 400,
                                  lineHeight: "normal",
                                },
                              }}
                              items={items}
                              tatalPaymentPrice={{
                                label: 'El cobro se realizará de forma recurrente y podrás cancelarlo en cualquier momento.',
                                total: '',
                                subLabel: ''
                              }}
                              title="Detalle de tu compra"
                              totalPymentVisibility={true}
                              key={"totalCard"}
                          />
                      </Box>
                      <CustomTypography
                          style={{
                            alignSelf: 'stretch',
                            color: 'var(--black-50, #8F8F8F)',
                            textAlign: 'center',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '20px', /* 153.846% */
                          }}
                      >
                        ¡No te preocupes! Con nosotros tienes asegurada una
                        experiencia de pago segura y certificada, por lo que tus datos
                        estarán a salvo en todo momento.
                      </CustomTypography>
                      <Button
                          type="button"
                         // disabled={loading}
                          onClick={(ev: any) => handleSubmit(ev)}
                          style={{
                            borderRadius: 20,
                            width: "100%",
                            padding: "14px 16px",
                            background: "var(--Rappi-neon, #FE3F23)",
                            opacity: '0.8',
                            fontSize: 16,
                            fontWeight: 600,
                            textTransform: 'uppercase',
                            lineHeight: 'normal',
                            color: "var(--white, #FFF)",
                          }}
                      >
                        PAGAR
                      </Button>
                    </LayOutContainer>
                  </Box>
              }
              </div>
            </div>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

export default MakePaymentTemplate;
