import React, {useEffect, useState} from 'react';
import {CustomButton} from 'components/atoms/Buttons/Buttons';
import {Box, Container, Grid, useMediaQuery, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DESCRIPTION, PROTECT} from "../../utils/Constants";
import {CustomTypography} from "../../components/atoms/Label/Label";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Question from "../../components/organisms/Questions/Question";
import CustomContainer from "../../components/organisms/Container/Container";
import MemberShipSlider, {
    IMemberShipBenefits,
    IMemberShipExtra
} from "../../components/organisms/MeetOurMemberships/MembershipSlider";
import Hero from "../../assets/Landing/hero.png"
import HeroDesktop from "../../assets/Landing/hero-desktop.png"
import {useSelector} from "react-redux";
import {isObject} from "formik";

const HomeLandingPage: React.FC = () => {
    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    const response = useSelector((state: any) => state.membershipProducts.response) as  | any  | null;
    const loading = useSelector((state: any) => state.membershipProducts.loading);

    type Producto = {
        plan_id:string,
        subtype:string,
        type: string;
        category: string;
        price:number,
        extra:Array<IMemberShipExtra>
        benefits: Array<IMemberShipBenefits>;
    };

    let [dataApi, setDataApi] = useState<Producto[]>([]);

    const eventScrollDown = () => {
        const element = document.getElementById('my-section');

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const callPhone = (phone:string) => {
        const phoneNumber = phone; // número de teléfono al que se realizará la llamada
        window.location.href = `tel:${phoneNumber}`;
    };

    React.useEffect(() => {
        window.dataLayer.push({
            event: 'Rappi_home_v',
            tipo_seguro: undefined,
            correo: undefined,
            telefono: undefined,
            page_location: window.location.href,
        });
    }, [])


    const premiumMemberShips = dataApi.filter(
        membership => membership.type === 'premium' && membership.category === "ingresos"
    );

    const generateArray =(objects:any)=>{
        let new_array: Array<Producto> = []
        Object.keys(objects).forEach(function(key, index) {
            if (isObject(objects[key])){
                new_array.push(objects[key]);
            }
        });
        return new_array;
    }

    useEffect(() => {
        if (response != null && response?.code === 200) {
            const data = response.data
            setDataApi(generateArray(data));
        }

    }, [response, loading]);


    const getText=(text:string= "")=>{
        const after_ = text.slice(text.indexOf('$'));
        return after_
    }
    return (
        <>
            <Grid
                container
                direction={isMobile ? "row" : "column"}
                justifyContent="space-between"
                alignItems="center"
            >
                {isMobile ?
                    <Grid item xs={12}>
                        <Box sx={{position: 'relative',}}>
                            <Box sx={{position: 'absolute',
                                display:'flex',flexDirection:'column' ,width:'100%',height:'100%'}}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    gap: 2,
                                    padding: '16px 16px 0 16px'
                                }}>
                                    <CustomTypography
                                        style={{
                                            color: "var(--White, #FFF)",
                                            fontSize: "29px",
                                            textAlign: 'left',
                                            fontStyle: "normal",
                                            fontWeight: 900,
                                            lineHeight: "normal",
                                            letterSpacing: "-0.36px",
                                        }
                                        }
                                    >
                                        Rappi e inter.mx quieren proteger tu chamba </CustomTypography>
                                    <CustomTypography
                                        style={{
                                            color: "var(--White, #FFF)",
                                            textAlign: 'left',
                                            fontSize: "19px",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            lineHeight: "normal",
                                            letterSpacing: "-0.36px",
                                        }
                                        }
                                    >
                                        Te ayudamos con $1500 al mes en caso de incapacidad de
                                        regalo.</CustomTypography>
                                </Box>

                                <Box sx={{display:'flex',width:'100%',height:"60%",justifyContent:'center',alignItems:'flex-end'}}>
                                    <CustomButton
                                        text={"¡Quiero mi seguro!"}
                                        variant={"contained"}
                                        onClick={() => eventScrollDown()}
                                        style={{
                                            fontWeight: 'bold',
                                            borderRadius: 20,
                                            boxShadow: 'none',
                                            color: "#FFF",
                                            background: 'linear-gradient(180deg, #1FD6B3 0%, #23ACB7 100%)',
                                            fontSize: "1rem",
                                            textTransform: "uppercase",
                                            maxWidth: "580px",
                                            border: "none",
                                            justifyContent: "center",
                                            width: "60%",
                                            height: "48px",
                                        }}
                                    />
                                </Box>


                            </Box>

                            <Box component={"img"} src={Hero} sx={{
                                height: 582,
                                width: '100%',
                                maxHeight: {xs: 582, md: 738, lg: 896},
                                maxWidth: {xs: '100%', md: 484, lg: '100%'},
                            }}/>
                        </Box>
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <Box sx={{position: 'relative',display:'flex',flexDirection:'row',}}>
                            <Box sx={{position: 'absolute',width:{sm:'40%',lg:'40%'},padding:'50px'}}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    gap: 2,

                                }}>
                                    <CustomTypography
                                        style={{
                                            color: "var(--White, #FFF)",
                                            fontSize: "42px",
                                            textAlign: 'left',
                                            fontStyle: "normal",
                                            fontWeight: 900,
                                            lineHeight: "normal",
                                            letterSpacing: "-0.72px",
                                        }
                                        }
                                    >
                                        Rappi e inter.mx quieren proteger tu chamba </CustomTypography>
                                    <CustomTypography
                                        style={{
                                            color: "var(--White, #FFF)",
                                            textAlign: 'left',
                                            fontSize: "24px",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            lineHeight: "normal",
                                            letterSpacing: "-0.36px",
                                        }
                                        }
                                    >
                                        Asegúrate y te cubrimos con $1,500 mxn en caso de incapacidad</CustomTypography>
                                </Box>
                                <CustomButton
                                    text={"¡Quiero mi seguro!"}
                                    variant={"contained"}
                                    onClick={() => eventScrollDown()}
                                    style={{
                                        marginTop:48,
                                        fontWeight: 'bold',
                                        borderRadius: 20,
                                        boxShadow: 'none',
                                        color: "#FFF",
                                        background: 'linear-gradient(180deg, #1FD6B3 0%, #23ACB7 100%)',
                                        fontSize: "1rem",
                                        textTransform: "uppercase",
                                        maxWidth: "580px",
                                        border: "none",
                                        justifyContent: "center",
                                        width: "60%",
                                        height: "48px",

                                    }}
                                />
                            </Box>
                            <Box component={"img"} src={HeroDesktop} sx={{

                                height: 582,
                                width: '100%',
                                maxHeight: {xs: 582, md: 738, lg: 896},
                                maxWidth: {xs: '100%', md: 484, lg: '100%'},
                            }}/>
                        </Box>
                    </Grid>
                }
            </Grid>
            <Container maxWidth="xl">
                <Box justifyContent={'center'} marginTop={10}>
                    {DESCRIPTION(premiumMemberShips.length >0 ? getText(premiumMemberShips[0].benefits[0].description): "-").map((desc, i) => {
                        return (
                            <div key={i}>
                                {desc.description}
                            </div>
                        )
                    })}
                </Box>
                <Box justifyContent={'center'} marginTop={10}>
                    <Typography
                        fontFamily={'Montserrat'}
                        fontSize={20}
                        fontStyle={'normal'}
                        fontWeight={'700'}
                        color={'#212121'}
                        align={'center'}
                        marginTop={5}
                    >
                        {'Rappi Protect by Inter.mx te protege en caso de'}
                    </Typography>
                </Box>
                <Box justifyContent={'center'} marginTop={10}>
                    {PROTECT.map((p, i) => {
                        return (
                            <Box key={i} sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 2,
                                mt: i !== 0 ? 5 : 0
                            }}>
                                <img src={p.image}/>
                                <CustomTypography
                                    style={{
                                        color: "var(--Black, #212121)",
                                        textAlign: "center",
                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                    }}>

                                    {p.titulo}</CustomTypography>
                                <CustomTypography
                                    style={{
                                        color: "var(--Black, #212121)",
                                        textAlign: "center",
                                        fontFamily: "Montserrat",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                    }}
                                >{p.description}</CustomTypography>
                            </Box>
                        )
                    })}
                </Box>

                <Box sx={{marginTop: 16}}>
                    <CustomContainer childComponent={
                        <MemberShipSlider
                            typeCurrent='free'
                            title='Conoce nuestro seguro'/>
                    }/>
                </Box>


                <Box justifyContent={'center'} marginTop={10} alignItems={'center'} textAlign={'center'}>
                    <Typography
                        fontFamily={'Montserrat'}
                        fontSize={24}
                        fontStyle={'normal'}
                        fontWeight={'700'}
                        color={'#212121'}
                        align={'center'}
                        marginTop={3}
                    >
                        {'¿Qué hacer en caso de accidente o enfermedad?'}
                    </Typography>
                    <Typography
                        fontFamily={'Montserrat'}
                        fontSize={20}
                        fontStyle={'normal'}
                        fontWeight={'400'}
                        color={'#212121'}
                        align={'center'}
                        marginTop={6}
                    >
                        {'Si sufriste un accidente o enfermedad llama al siguiente número para repórtalo'}
                    </Typography>
                </Box>
                <Box justifyContent={'center'} marginTop={10} alignItems={'center'} textAlign={'center'}>
                    <CustomButton
                        text={"55 6677 8899"}
                        variant={"contained"}
                        endIcon={<ArrowForwardIosIcon fontSize={'small'}/>}
                        //onClick={() => {return console.log("si")}}
                        onClick={() => {
                            callPhone("5566778899")
                            window.dataLayer.push({
                                event: 'Rappi_accidente_reporte',
                                option: 'telefono'
                            });
                        }}
                        style={{
                            fontWeight: 'bold',
                            borderRadius: 20,
                            boxShadow: 'none',
                            color: "#FFF",
                            background: 'linear-gradient(180deg, #1FD6B3 0%, #23ACB7 100%)',
                            fontSize: "1rem",
                            textTransform: "none",
                            padding: "0px",
                            maxWidth: "580px",
                            border: "none",
                            justifyContent: "center",
                            width: "100%",
                            height: "48px",

                        }}
                    />
                </Box>
                <Box mt={10}/>
                <Question/>
            </Container>
        </>
    );
};

export default HomeLandingPage;
