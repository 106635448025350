// @ts-ignore
// @ts-ignore

import React from 'react';
import { styled } from 'styled-components';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Toolbar,
    IconButton,
    Fade,
    Menu,
    MenuItem, Box, Divider, Grid, Stack
} from '@mui/material';
import Logo from '../../../../assets/MiniLogin/IconMiniLogo';
import SubMenu from '../../../molecules/SubMenu/SubMenu';
import { CustomTypography } from '../../../atoms/Label/Label';
import { CustomButton } from '../../../atoms/Buttons/Buttons';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Rappi from "../../../../assets/Logo/rappiLogo.svg";
import {ReactComponent as SoyAppi} from "../../../../assets/Logo/SoyAppi.svg";

const ToolbarElement = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  gap: 8rem;
`;

const MenuContentElement = styled.div`
    display: flex;
    flex-direction: row;    
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const MenuSeccionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
`;

interface MenuContentProps {
    setOpenModal: any;
    body: Array<any>;
    visibleMenu: boolean;
    isAuthState: boolean;
}

const MenuContent: React.FC<MenuContentProps> = ({ isAuthState, setOpenModal, body, visibleMenu }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [eventHandler, setEventHandler] = React.useState<null | HTMLElement>(null);
    const openMenuHandler = Boolean(eventHandler);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const currentPath = window.location.pathname;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(open ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setEventHandler( eventHandler ? null : event.currentTarget);
    };

    const closeMenu = () => {
        setEventHandler(null);
    };

    return(
        <React.Fragment>
            <ToolbarElement sx={{
                display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
                justifyContent: 'space-between'
            }}>
                <Stack
                    style={{ flexShrink: 0 }}
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    divider={<Divider orientation="vertical" flexItem />}
                >
                    <span>
                        <Link to={"/"}>
                            <img src={Rappi} />
                        </Link>
                    </span>
                    <span>
                        <Link to={"/"}>
                            <Logo />
                        </Link>
                    </span>
                </Stack>
                <MenuSeccionWrapper>
                    {visibleMenu ? <MenuContentElement>
                        {
                            body.map(({item, subItems, url, onClick, active}) => {
                                return subItems ? <div key={item}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '4px'
                                    }}>
                                        <CustomTypography
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 600
                                            }}
                                            item={item}
                                            handleClick={handleClick}
                                        />
                                        {open ? <ExpandLess style={{ color: '#000' }} /> : <ExpandMore  style={{ color: '#000' }} />}

                                    </div>
                                    <Menu
                                        id="fade-menu"
                                        MenuListProps={{
                                        'aria-labelledby': 'fade-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        style={{
                                            top: '28px'
                                        }}
                                    >
                                        {
                                            subItems.map(({label, onClick}: any) => <MenuItem key={label} onClick={(ev) => {
                                                onClick(ev);
                                            }}>{label}</MenuItem>)
                                        }
                                    </Menu>
                                </div>
                                :
                                url ?
                                <Link
                                    key={item}
                                    target="_blank"
                                    to={url}
                                    style={{
                                        textDecoration: 'none',
                                        padding: '5px',
                                        color: 'black',
                                        fontSize: 16,
                                        fontWeight: 600,
                                        textAlign: 'center'
                                    }}
                                    onClick={() => {
                                       // console.log('link')
                                        window.dataLayer.push({
                                            event: "Rappi_menu",
                                            option: item,
                                        })
                                    }}
                                >
                                    <CustomTypography
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 600
                                        }} item={item} />
                                </Link>
                                :
                                <CustomButton
                                    key={item}
                                    text={item}
                                    onClick={onClick}
                                    size={"medium"}
                                    variant={"contained"}
                                    style={{
                                        color: active ? "#039ECC" : "#000000",
                                        backgroundColor: "transparent",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        fontStyle: "normal",
                                        padding: "8px 10px",
                                        minWidth: "64px",
                                        border: "none",
                                        justifyContent: "flex-start",
                                        width: "fit-content",
                                        textWrap: "nowrap"
                                    }}
                                />
                            })
                        }
                     {/*   {currentPath === '/' && !isAuthState && <CustomButton
                            text={"Iniciar sesión"}
                            onClick={() => navigate('/iniciar-sesion')}
                            size={"medium"}
                            variant={"contained"}
                            style={{
                                color: "#039ECC",
                                backgroundColor: "transparent",
                                fontSize: "14px",
                                fontWeight: "600",
                                fontStyle: "normal",
                                padding: "8px 10px",
                                minWidth: "64px",
                                border: "1.5px solid #039ECC",
                                justifyContent: "flex-start",
                                width: "fit-content",
                                textWrap: "nowrap"
                            }}
                        />}*/}
                    </MenuContentElement> : <div></div>}
                </MenuSeccionWrapper>
            </ToolbarElement>
            <ToolbarElement sx={{
                display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' },
                justifyContent: 'space-between',
                padding: 0
            }}>
                <MenuSeccionWrapper>
                    <Stack
                        style={{ flexShrink: 0 }}
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        divider={<Divider sx={{height:'80%'}} orientation="vertical" variant="middle" flexItem={false} />}
                    >
                    <Link to={"/"} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <img src={Rappi} />
                    </Link>
                    <Link to={"/"} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Logo />
                    </Link>
                    </Stack>
                    {visibleMenu ? <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={openMenu}
                    >
                        <MenuIcon style={{
                            fill: '#000',
                            width: 32,
                            height: 32
                        }}
                        />
                    </IconButton> : <div></div>}
                </MenuSeccionWrapper>

                    <Menu
                        id="menu-appbar"
                        anchorEl={eventHandler}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={openMenuHandler}
                        onClose={closeMenu}
                        TransitionComponent={Fade}
                        style={{
                            color: '#000',
                            textTransform: 'none',
                            fontWeight: 600,
                            fontSize: '16px'
                        }}
                        PaperProps={{sx: {width: '800px'}}}
                    >
                    {
                        body.map(({item, subItems, url, onClick}) => {
                        return subItems ?
                            <SubMenu
                                key={item}
                                label={item}
                                items={subItems}
                            />
                            :
                            url ?
                            <MenuItem key={item} style={{ justifyContent: 'center' }} onClick={() => {
                                window.open(url, '_blank');
                                closeMenu();
                            }}><b>{item}</b></MenuItem>
                            :
                            <MenuItem key={item}  style={{ justifyContent: 'center' }} onClick={(ev) => {
                                onClick(ev);
                                closeMenu();
                            }}>
                                <b>{item}</b>
                            </MenuItem>
                        })
                    }
                    </Menu>
            </ToolbarElement>
        </React.Fragment>
    );
};

export default MenuContent;
