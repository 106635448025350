import React from 'react';

interface BlueCircleProps {
    width?: number;
    height?: number;
    fill?: string;
    stroke?: string;
}

const InfoCheck: React.FC<BlueCircleProps> = ({
    width = 33,
    height = 32,
    fill= '#039ECC',
    stroke="#77CAE3"
}) => {
  return(
      <svg width={width} height={height} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0101 20.646L8.42827 15.6338L6.901 17.3045L13.0101 23.9875L26.101 9.66684L24.5737 7.99609L13.0101 20.646Z" fill="#5AB52F"/>
    </svg>
  );
};

export default InfoCheck;
