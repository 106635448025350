import React from 'react';
import { CustomTypography } from '../../../atoms/Label/Label';
import { useMediaQuery } from '@mui/material';

type FormSteppersProps = {
    title: string[];
    subTitle?: string[];
    activeStep: number;
    steps: string[];
}

const FormSteppers: React.FC<FormSteppersProps> = ({
    title,
    subTitle,
    activeStep,
    steps
}) => {
    const matches = useMediaQuery('(min-width:600px)');
    
    return <React.Fragment>
        {title[activeStep] ? <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: '1rem'
        }}>
            
            <CustomTypography 
                item={title[activeStep][0].toUpperCase() + title[activeStep].slice(1)} 
                style={{ 
                    display: 'block',
                    fontSize: !matches ? 20 : 28,
                    textAlign: 'center', 
                    fontWeight: 700,
                    fontStyle: 'normal',
                    lineHeight: 'normal', 
                    alignSelf: 'stretch',
                    padding: !matches ? '0 1rem' : "",
                    // textWrap: !matches ? 'balance' : ""
                }} 
            />
            {subTitle && subTitle[activeStep] && <CustomTypography 
                item={subTitle[activeStep]} 
                style={{ 
                    display: 'block',
                    fontSize: !matches ? 14 : 18,
                    textAlign: 'center', 
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: 'normal', 
                    padding: !matches ? '0 1rem' : ""
                }} 
            />}
            <label>{steps[activeStep]}</label>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: 5
            }}>
                {steps[activeStep] && steps.map((label: string, key: number) => (
                    <span key={label} style={{borderRadius: 8, background: activeStep >= key ? 'var(--Rappi-neon, #FE3F23)' : 'var(--Dark-beige, #F5E5CE)', padding: 4, flexGrow: 1}}></span>
                ))}
            </div>
        </div> : null}
    </React.Fragment>
}

export default FormSteppers;