import React from "react";
import { styled } from "styled-components";
import { CardMedia, CardContent, Card, Typography } from "@mui/material";
import { CustomButton } from "../../atoms/Buttons/Buttons";
import ManiPosesModal from "../../../assets/CustomerPortalAssets/ManiposesModal.svg";

const CardHeader = styled.div`
  borderradius: 8px 8px 0px 0px;
  background: #ffd56b;
  border-bottom: 1px solid #fff;
  @media (min-width: 600px) {
    border-bottom: none;
    border-right: 1px solid #fff;
  }
`;

type CardActionProps = {
  cardType?: string,
  cardCategory?: string,
  cardTitle?: string,
  cardDescription?: string,
  subType?: string,
  visibleImg?: boolean,
  cardContent?: string | React.ReactNode,
  imgSrc: any;
  style?: object;
  styleTextContent?: object;
  styleImg?: object;
  styleImgCard?: object; 
  styleButton?: object;
  styleButtonSecondary?: object;
  buttonText?: string;
  buttonTextSecondary?: string;
  onClick?: any;
  onClickSecondary?: any; 
  secundaryButton?: boolean;
  setOpenModal?: any;
}

const CardAction: React.FC<CardActionProps> = ({
  cardType, 
  visibleImg,
  cardContent,
  imgSrc,
  style,
  styleTextContent,
  styleImg,
  styleImgCard,
  styleButton,
  styleButtonSecondary,
  buttonText = "Quiero ser premium",
  buttonTextSecondary = "Continuar",
  onClick,
  onClickSecondary,
  secundaryButton
}) => {

  React.useEffect(() => {
    if (window.location.pathname !== "/registrate" ){
      if (window.location.pathname !== "/registrate" ){
        if (cardType == "premium"){
          
        }
      }
    }
     
  }, [])

  
  if (cardType != undefined){
    const cardTypeStorage = sessionStorage.setItem('card-type-storage', JSON.stringify(cardType));
  }


  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row", md: "row" },
        maxWidth: 725,
        borderRadius: "8px",
      }}
    >
      {!visibleImg && <CardHeader style={{ flex: 1, ...styleImg }}>
        <CardMedia
          component="img"
          image={imgSrc}
          alt="mani poses"
          sx={{
            padding: "1em 0 0 0",
            margin: "auto",
            width: 230,
            objectFit: "contain",
            ...styleImgCard
          }}
        />
      </CardHeader>}
      <CardContent
        style={{
          flex: 1,
          ...style
        }}
        sx={{
          border: "1px solid #E6B331",
          background: "#E6B331",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
          padding: { xs: "1rem", md: "40px" },
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="p"
          sx={{ textWrap: "balance" }}
          style={{
            textAlign: "center",
            color: "var(--white, #FFF)",
            fontSize: 20,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            ...styleTextContent
          }}
        >
          {cardContent}
        </Typography>
        <CustomButton
          onClick={() => {
            if(onClick){
              onClick();
            }
          }}
          text={buttonText}
          style={{ 
            color: "#E6B331",
            borderRadius: 8,
            border: "1px solid #FFD66F",
            background: "var(--white, #FFF)",
            textAlign: "center",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "uppercase",
            padding: "14px 16px",
            width: "100%",
            ...styleButton
          }}
        />
        {secundaryButton &&
          <CustomButton
            onClick={() => {
                if(onClickSecondary){
                  onClickSecondary();
                }
            }}
            text={buttonTextSecondary}
            style={{ 
              color: "#E6B331",
              borderRadius: 8,
              border: "1px solid #FFD66F",
              background: "var(--white, #FFF)",
              textAlign: "center",
              fontSize: 16,
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textTransform: "uppercase",
              padding: "14px 16px",
              width: "100%",
              ...styleButtonSecondary
            }}
          />
        }
      </CardContent>
    </Card>
  );
};

export default CardAction;
