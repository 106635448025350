import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate
} from 'react-router-dom';
import HomeLandingPage from 'pages/HomeLandingPage';
import Navbar from 'components/organisms/Navbar/Navbar';
import FooterInter from 'components/atoms/Footer/Footer';
import SignUp from 'pages/SignUp';
import SuccessSignUp from 'pages/SuccessSignUp';
import WeSorry from 'pages/WeSorry';
import ErrorPage from 'pages/ErrorPage';
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";
import BASE_CONFIG from "env/env";
import UpgradeMembership from 'pages/UpgradeMembership';
import useFetch from 'hooks/useFetch';
import { apiSuccess, apiLoading, apiError } from 'api/productsSlice';
import NotFound from 'pages/NotFound';
import RedirectRappiTrackingId from "pages/RedirectRappiTrackingId";
import RappiFAQs from "pages/RappiFAQs";

const stripePromise = loadStripe(BASE_CONFIG.stripe.publicKey);

declare global {
  interface Window {
    dataLayer: any;
  }
}

const App: React.FC = () => {
  const {fetchData } = useFetch();

  const ScrollToTop = () => {
    const location = useNavigate();

    React.useEffect(() => {
      const body = document.querySelector("body") as HTMLElement
      body.scrollIntoView({ behavior: 'smooth', block: 'start' })
      body.scrollTo(0, 0);
    }, [location]);

    return null;
  };

  React.useEffect(() => {
    fetchData('/catalogs/rappi-continuity','GET', {}, {}, apiLoading, apiSuccess, apiError);
  }, []);

  return (
    <BrowserRouter>
      <Elements stripe={stripePromise}>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path='/' element={<HomeLandingPage />} />
            <Route path='/preguntas-frecuentes' element={<RappiFAQs />} />
            <Route path='/registrate/:idStepper' element={<SignUp />} />
            <Route path='/exito-registro' element={<SuccessSignUp />} />
            <Route path='/lo-sentimos' element={<WeSorry />} />
            <Route path='/error' element={<ErrorPage />} />
            <Route path='/actualizar' element={<UpgradeMembership />} />
            <Route path='/rappi/:id' element={<RedirectRappiTrackingId />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          <FooterInter />
        </Elements>
    </BrowserRouter>
  );
}

export default App;
