import React from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import BasicModalContent from "./BasicModalContent/BasicModalContent";
import { CloseOutlined } from "@mui/icons-material";

interface BasicModalProps {
  setOpenModal: any;
  open: boolean;
  bodyDialog?: React.ReactNode;
  styleContent?: object;
  closeButton?: boolean;
  disableBackdropClick?: boolean;
  styleRoot?: object;
  onClick?: any;
}

const BasicModal: React.FC<BasicModalProps> = ({
  open,
  setOpenModal,
  bodyDialog,
  styleContent,
  closeButton,
  disableBackdropClick,
  styleRoot,
  onClick
}) => {
  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "8px",
            backgroundColor: closeButton ? "transparent" : "",
            gap: "1rem",
            ...styleRoot,
          },
        }}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' || !disableBackdropClick) {
            setOpenModal(false)
            onClick && onClick();
          }
        }}
      >
        {closeButton ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenModal(false)
                onClick && onClick();
              }}
              sx={{
                right: 0,
                color: (theme) => theme.palette.grey[500],
                backgroundColor: "#FFF",
                margin: ".5rem 0",
                width: 32,
                height: 32,
                "&:hover": {
                  backgroundColor: "#FFF",
                },
              }}
            >
              <CloseOutlined />
            </IconButton>
          </div>
        ) : null}
        <DialogContent style={styleContent}>
          {bodyDialog ? bodyDialog : <BasicModalContent />}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default BasicModal;
