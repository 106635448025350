import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import Logo from '../../../assets/Logo/Logo';
import ErrorIcon from '@mui/icons-material/Error';

import './loading.css';
interface LoadingProps {
    isLoaded: boolean;
    isError?: boolean;
    ErrorMessage?: string;
    style?: React.CSSProperties;
    interTextMessage?: string;
}

const styleLoading: React.CSSProperties = {
    backgroundColor: 'white',
    zIndex: '1000',
    height: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
}

const styleLabel: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    top: '50',
    left: '50',
    justifyContent: 'center',
    alignItems: 'center'
}
const styleLogo: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px'
}

const LoadingComponent: React.FC<LoadingProps> = ({ isLoaded, style, isError, ErrorMessage, interTextMessage }) => {
    return (<>
        <div className='LoadingComponent' style={isLoaded && !isError ? { ...styleLoading, ...style, display: 'none' } : { ...styleLoading, ...style, display: 'flex' }}>
            <div style={styleLabel}>
                <div className='LogoLoading' style={isLoaded ? { ...styleLogo, display: 'none' } : { ...styleLogo, display: 'flex' }}>
                    <div className="itemLogo">
                        <Logo></Logo>
                    </div>
                    <div className="itemLogo" style={{ paddingLeft: '30px' }}>
                        <CircularProgress></CircularProgress>
                    </div>
                    <div><b>{interTextMessage}</b></div>
                </div>
                <div className='LogoError' style={isLoaded && isError && isError === true ? { display: 'flex' } : { display: 'none' }}>
                    <div className="itemLogo" style={{ paddingLeft: '0px' }}>
                        <ErrorIcon style={{ fill: 'red', fontSize: '50px' }}></ErrorIcon>
                    </div>
                    <div className='itemLogo'>
                        <p style={{ color: 'red' }}>
                            {ErrorMessage ? ErrorMessage : 'Hubo un error'}
                        </p>
                    </div>
                </div>
            </div>
        </div >
    </>)
}

export default LoadingComponent;