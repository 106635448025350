import React, { useState } from "react";
import { CustomTypography } from "components/atoms/Label/Label";

const PaymentFrequency: React.FC = () => {

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 24
    }}>
      <CustomTypography
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
        }}
      ></CustomTypography>
    </div>
  );
};

export default PaymentFrequency;
