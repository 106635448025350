
import { IConfig, ConfigEnv, ModesTypes, MODES } from './types';
const MODE: ModesTypes = MODES.qa;

//export const baseURL = 'https://teregalamosunseguro.inter.mx';    //Prod
export const baseURL = 'https://qa-teregalamosunseguro.inter.mx/'   //QA
//export const baseURL = 'http:/ / localhost:3000';                 //DEV

//Configuration by envirotments
const MAIN_CONFIG: ConfigEnv = {
    develop: {
        base: 'http://localhost:3000', //For redirection
        backend: 'https://demos.inter.mx/freemium/', //For API BACKEND
        stripe: {
            publicKey: 'pk_test_51Nro7LIGOnYkVbIoh4Yr1WseaM50bi4j9VCY6msB6LcHoUk9MxHdZGjO8PCrBMNXNBTRbeXVvJXN9F05Ahx12u4r00uR0avozu'
        }
    },
    qa: {
        base: 'inter-rappi-qa.web.app',
        backend: 'https://demos.inter.mx/freemium/',
        stripe: {
            publicKey: 'pk_test_51Nro7LIGOnYkVbIoh4Yr1WseaM50bi4j9VCY6msB6LcHoUk9MxHdZGjO8PCrBMNXNBTRbeXVvJXN9F05Ahx12u4r00uR0avozu'
        }
    },
    production: {
        base: 'inter-rappi-prod.web.app',
        backend: 'https://back-tpsg.inter.mx/freemium/',
        stripe: {
            publicKey: 'pk_live_51Nro7LIGOnYkVbIovbKk22roP47KqkhC55gBLFTzkpjRd5soU7R12hNZWJqD4xem0MTBikELTFu4xNT83r8hnQbL00xZv55J83'
        }
    }

}

const BASE_CONFIG: IConfig = MAIN_CONFIG[MODE];
// console.info("CONFIGURATION=> " + MODE, BASE_CONFIG);
export default BASE_CONFIG;




