import React from "react";
import WeSorryTemplate from "components/templates/WeSorry"

const WeSorry:React.FC = () => {

    return (
        <WeSorryTemplate />
    )
}

export default WeSorry;
