import { useDispatch } from 'react-redux';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { InterInstance } from '../api/config';
import RenderAlert from '../components/organisms/Modal';

interface UseFetchResult {
  fetchData: (url: string, method: AxiosRequestConfig['method'], data?: any, headers?: AxiosRequestConfig['headers'], apiLoading?: any, apiSuccess?: any, apiError?: any) => void;
}

const useFetch = <T>(): UseFetchResult => {
  const dispatch = useDispatch();

  const fetchData = async (url: string, method: AxiosRequestConfig['method'], data?: any, headers?: AxiosRequestConfig['headers'], apiLoading?: any, apiSuccess?: any, apiError?: any) => {
    await dispatch(apiLoading(true));

    try {
      let apiResponse: AxiosResponse<any>;
      const messageError = JSON.stringify({error: true, message: 'Error al obtener los datos'});

      switch (method) {
        case 'GET':
            apiResponse = await InterInstance.get(url, { headers });
            if(apiResponse?.data){
              dispatch(apiSuccess(apiResponse.data));
            }else {
              throw Error(messageError);
            }
          break;
        case 'POST':
          apiResponse = await InterInstance.post(url, data, { headers });
          if(apiResponse?.data){
            dispatch(apiSuccess(apiResponse.data));
          }else {
            throw Error(messageError);
          }
          break;
        case 'PUT':
          apiResponse = await InterInstance.put(url, data, { headers });
          if(apiResponse?.data){
            dispatch(apiSuccess(null));
          }else {
            throw Error(messageError);
          }
          break;
        case 'PATCH':
          apiResponse = await InterInstance.patch(url, data, { headers });
          if(apiResponse?.data){
            dispatch(apiSuccess(null));
          }else {
            throw Error(messageError);
          }
          break;
        case 'DELETE':
          apiResponse = await InterInstance.delete(url, { headers });
          if(apiResponse?.data){
            dispatch(apiSuccess(null));
          }else {
            throw Error(messageError);
          }
          break;
        default:
          throw new Error('Método HTTP no válido');
      }

    } catch (error: any) {
      const status = error?.response?.status || 400;
      const message = error?.response?.data?.data?.message || 'Error al realizar la petición';
      dispatch(apiError(error || 'Error al realizar la petición'));
      RenderAlert({severity: "info", message});
    }
  };

  return { fetchData };
};

export default useFetch;
