import * as React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import QuestionsPlus from '../../../assets/Questions/QuestionsPlus';
// import Link from '@mui/material/Link';
import {Link} from 'react-router-dom';
import './question.css';
import {Accordion, AccordionSummary, Typography} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import {ArrayFAQsHome} from "utils/Constants";

export default function BasicAccordion() {

    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [expandedSecond, setExpanded2] = React.useState<string | false>(false);
    const [expandedThird, setExpanded3] = React.useState<string | false>(false);
    const [expandedFourth, setExpanded4] = React.useState<string | false>(false);
    const [expandedFived, setExpanded5] = React.useState<string | false>(false);


    const validateOption = (id: number) => {
        if (id === 1) {
            return expanded
        } else if (id === 2) {
            return  expandedSecond
        } else if (id === 3) {
            return  expandedThird
        } else if (id === 4) {
            return  expandedFourth
        } else if (id === 5) {
            return  expandedFived
        }
    }

    const validateOptionSet = (id: number, isExpanded: boolean , panel:string) => {
        if (id === 1) {
            setExpanded(isExpanded ? panel : false)
        } else if (id === 2) {
            setExpanded2(isExpanded ? panel : false)
        } else if (id === 3) {
            setExpanded3(isExpanded ? panel : false)
        } else if (id === 4) {
            setExpanded4(isExpanded ? panel : false)
        } else if (id === 5) {
            setExpanded5(isExpanded ? panel : false)
        }
    }

    //SI CAMBIAN TITULO DE PREGUNTAS EN LA SECCION CAMBIAR EL TITULO EN EL PARAMETRO DE FAQ DE LOS EVENTOS DE GTM
    const handleChange =
        (panel: string,id:number,title:string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            validateOptionSet(id,isExpanded, panel)
                if (isExpanded) {
                    window.dataLayer.push({
                         event: 'Rappi_faq',
                         faq: title,
                     })
                }

        };

    const AcordionCustom: React.CSSProperties = {
        boxShadow: 'unset',
        borderRadius: '0px',
    }
    const AcordionContainer: React.CSSProperties = {
        borderBottom: '2px solid gray'
    }

    return (
        <div>
            <Typography
                color={'var(--black, #212121)'}
                textAlign={'center'}
                fontSize={'20px'}
                fontStyle={'normal'}
                fontWeight={700}
            >
                Preguntas Frecuentes
            </Typography>
            {
                ArrayFAQsHome.map((item:any, index:number) =>
                    (
                        <div style={AcordionContainer} key={index}>
                            <Accordion style={AcordionCustom} expanded={validateOption(item.id)   === 'panel'+item.id}
                                       onChange={handleChange('panel' + item.id, item.id, item.title)}>
                                <AccordionSummary
                                    expandIcon={validateOption(item.id) === 'panel' + item.id ? < RemoveIcon/> : < QuestionsPlus/>}
                                >
                                    <Typography
                                        fontFamily={'Montserrat'}
                                        color={'var(--black-75, #595959)'}
                                        fontSize={'16px'}
                                        fontWeight={700}

                                    >{item.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        fontFamily={'Montserrat'}
                                        color={'var(--black-75, #212121)'}
                                        fontSize={'16px'}
                                        fontWeight={400}
                                    >
                                        {item.description}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    )
                )
            }

            <Typography
                fontFamily={'Montserrat'}
                color={'var(--black, #212121)'}
                fontSize={'16px'}
                fontWeight={400}
                style={{
                    cursor: 'pointer',
                    marginTop: '40px'
                }}
                textAlign={"center"}

            >
                Para más info consulta nuestra sección de
            </Typography>
            <Link to={'/preguntas-frecuentes'}>
                <Typography
                    fontFamily={'Montserrat'}
                    color={'var(--black, #009DD0)'}
                    fontSize={'16px'}
                    fontWeight={400}
                    style={{cursor: 'pointer'}}
                    textAlign={"center"}
                >
                    Preguntas Frecuentes
                </Typography>
            </Link>
        </div>
    );
}
