/* eslint-disable no-restricted-globals */
import React, {
  useState,
  // useRef, 
  useContext
} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomTypography } from "../../atoms/Label/Label";
import { styled } from "styled-components";
// import '../Login/ReferredComponent.css';

import guy from '../../../assets/ManiTinking/banner.png';
import { CustomButton } from "../../atoms/Buttons/Buttons";
import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";

const LayOutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 558px;
  margin: auto;
`;

type ErrorProps = {};

const NotFound: React.FC<ErrorProps> = () => {
  const navigate = useNavigate();
  const [isBack, setBack] = useState<boolean>(false);

  return (
    <div style={{ marginTop: 40, marginBottom: 80 }}>
      <LayOutContainer>
        <CustomTypography
          style={{
            fontSize: 24,
            fontWeight: 700,
            letterSpacing: "0.5px",
            textAlign: "center",
            Padding: "0 1rem",
            textWrap: "balance",
          }}
        >
          ¡Oops! Parece que has encontrado una página que no existe
        </CustomTypography>
        <br />
        <div className='imageBanner'>
          <div className="guy">
            <img src={guy} alt="" width={250} />
          </div>
        </div>
        <CustomTypography
          style={{
            fontSize: 24,
            fontWeight: 700,
            letterSpacing: "0.5px",
            textAlign: "center",
            Padding: "0 1rem",
            textWrap: "balance",
          }}
        >
        </CustomTypography>
        <Button
          type="button"
          size={"medium"}
          variant={"contained"}
          onClick={() => navigate('/')}
          style={
            {
              borderRadius: 20,
              width: "100%",
              padding: "14px 16px",
              background: "var(--Rappi-neon, #FE3F23)",
              color: "#FFF",
              opacity: '0.8',
              fontSize: 16,
              fontWeight: 600,
              textTransform: 'uppercase',
              lineHeight: 'normal'
            }
          }
        >
          Regresar
        </Button>
      </LayOutContainer>
    </div>
  );
};

export default NotFound;
